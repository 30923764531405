import { EventBus } from "@/event-bus";
import { Component, Prop, Vue } from "vue-property-decorator";
import ColumnViewTable from "./ColumnViewTable.component";
import Template from "./OverviewASAP.template.vue";
import RowViewTable from "./RowViewTable.component";
@Component({
  mixins: [Template],
  components: {
    RowViewTable,
    ColumnViewTable
  }
})
export default class OverviewASAP extends Vue {
  @Prop({ default: {} }) public report!: {
    [key: string]: { headers: any[]; data: any[] };
  };
  public show: boolean = false;
  public rowHeights: number[] = [];
  public tablesMounted: number = 0;

  public emitPrint() {
    EventBus.$emit("print", {
      component: ColumnViewTable,
      props: {
        report: this.report,
        rowHeights: this.rowHeights
      }
    });
  }
  public tableMounted() {
    const tableRows: HTMLCollection = document.getElementsByClassName(
      "row-level"
    );
    for (const row of tableRows) {
      this.rowHeights.push(row.clientHeight);
    }
    this.emitPrint();
    this.$emit("removeOverview");
  }
}
