var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(
      _vm.showReport &&
      _vm.voidedTransferReport &&
      _vm.selectedReport[1] &&
      _vm.selectedReport[1].value === 'voided_inventory_transfers'
    )?_c('voided-transfer-report',{attrs:{"data":_vm.voidedTransferReport.reports,"loading":_vm.loading,"pagination":_vm.voidedTransferReport.pagination},on:{"onChangePagination":_vm.voidedTransfersPaginate}}):_vm._e(),_vm._v(" "),(
      _vm.showReport &&
      _vm.selectedReport[1] &&
      _vm.selectedReport[1].value === 'retail_summary_report'
    )?_c('sales-summary',{attrs:{"reports":_vm.transactionSummaryReport}}):(
      _vm.showReport && _vm.transferReport && _vm.getSelectedReportCategory === 'transfer'
    )?_c('transfer-report',{attrs:{"data":_vm.transferReport.reports,"loading":_vm.loading,"pagination":_vm.transferReport.pagination},on:{"onChangePagination":_vm.transfersPaginate}}):(
      _vm.showReport &&
      _vm.getSelectedReportCategory === 'money' &&
      _vm.selectedReport[1].value !== 'money_actions'
    )?_c('z-out-report',{attrs:{"reports":_vm.zreports}}):(_vm.showReport && _vm.getSelectedReportCategory === 'timeclock')?_c('timeclock-report',{attrs:{"data":_vm.timeClockReport,"loading":_vm.loading},on:{"sortChange":_vm.timeclockSortChange,"paginate":_vm.timeclockPaginate,"sizeChange":_vm.timeclockSize}}):(
      _vm.appliedFilters.breakdown_by&& _vm.appliedFilters.breakdown_by!=='PRODUCT' &&
      _vm.showReport &&
      _vm.retailSalesBreakDown &&
      _vm.selectedReport[1] &&
      _vm.selectedReport[1].value === 'retail_sales_breakdown'
    )?_c('retail-sales-breakdown',{attrs:{"data":_vm.retailSalesBreakDown.reports,"loading":_vm.loading,"pagination":_vm.retailSalesBreakDown.pagination},on:{"onChangePagination":_vm.retailSalesBreakDownPaginate}}):(_vm.showReport && !_vm.loadingSort)?[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"border":"","headers":_vm.report.headers,"cell-class-name":_vm.cellClassName,"height":"480","data":_vm.report.data,"span-method":_vm.objectSpanMethod,"empty-text":_vm.emptyTextData},on:{"sort-change":_vm.sortChange}},[(_vm.report.data.length)?_c('div',[_c('el-table-column',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"prop":"","min-width":"-10"}}),_vm._v(" "),_vm._l((_vm.report.headers),function(data,index){return _c('el-table-column',{key:index,attrs:{"sortable":"","width":"255","prop":data.value,"label":data.text},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return _c('div',{},[(data.value === 'limit_value' && _vm.isCustomerList)?_c('span',[(row.extended_limit === 'Yes')?_c('span',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.purify(row.limit_value))}})]):_c('span',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.purify(row[data.value]))}})])]):_c('span',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.purify(row[data.value]))}})])])}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(data.text))]),_vm._v(" "),(data.value === 'limit_value' && _vm.isCustomerList)?_c('span',[_c('v-tooltip',{attrs:{"top":""}},[_c('v-icon',{attrs:{"slot":"activator","size":"16"},slot:"activator"},[_vm._v("fas fa-info-circle")]),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.$t("customer_limit_value_tooltip")))])],1)],1):_vm._e()])],2)})],2):_vm._e()]),_vm._v(" "),(_vm.report && _vm.report.pagination)?_c('el-pagination',{staticClass:"mt-3 ml-5",attrs:{"page-size":_vm.report.pagination.itemsPerPage,"current-page":_vm.report.pagination.currentPage,"page-sizes":[10, 25, 50, 75, 100],"total":_vm.report.pagination.total,"page-count":_vm.report.pagination.lastPage,"background":"","layout":"prev, pager, next, sizes, total"},on:{"current-change":_vm.currentData,"size-change":_vm.onSizeChange}}):_vm._e(),_vm._v(" "),(_vm.asapReport)?_c('overview-asap',{attrs:{"report":_vm.asapReportData},on:{"removeOverview":function($event){_vm.asapReport = false}}}):_vm._e()]:_c('v-layout',{staticClass:"no-report-selected",attrs:{"align-center":"","justify-center":""}},[_c('h3',{staticClass:"no-report-selected__message"},[_vm._v("\n      "+_vm._s(_vm.$t("reports_group.no_selected"))+"\n    ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }