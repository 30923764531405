import { BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import unset from "lodash/unset";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./MultiLevelSelectField.template.vue";

interface Item {
  [key: string]: any;
  children: Item[];
  value: string;
}

@Component({
  mixins: [Template]
})
export class MultiLevelSelectComponent extends Vue {
  @Prop() public placeholder!: string;
  @Prop() public items!: Item[];
  @Prop() public itemLabel!: string;
  @Prop() public itemKey!: string;
  @Prop({ required: true }) public running!: BooleanCheck;

  protected stack: Item[] = [];

  protected submenu: {
    position: { x: number; y: number };
    items: any[];
    parent?: any;
  } = {
    position: {
      x: 0,
      y: 0
    },
    items: []
  };

  protected opened: boolean = false;
  protected submenuOpened: boolean = false;
  protected selected: boolean = false;
  protected selectedLabel: string = "";

  public onSelectedChange() {
    this.$emit("input", cloneDeep(this.stack));
  }

  public select(item: Item) {
    this.stackValue(item);
    this.changeLabel();
    this.onSelectedChange();
    this.closeAll();
  }

  public changeLabel() {
    this.selectedLabel = this.itemLabel
      ? this.stack[this.stack.length - 1][this.itemLabel]
      : this.stack[this.stack.length - 1];
  }

  protected getItemLabel(i: any) {
    return this.itemLabel ? i[this.itemLabel] : i;
  }
  protected getItemValue(i: any) {
    return this.itemKey ? i[this.itemKey] : i;
  }
  protected openChildrenMenu(event: PointerEvent, item: Item, parent?: any) {
    if (!parent) {
      this.stack = [];
    }
    this.stackValue(item);
    // @ts-ignore
    const listElement = (event.target as HTMLElement).parentElement
      .parentElement;
    if (listElement) {
      this.submenuOpened = false;
      setTimeout(() => {
        this.submenu.parent = parent;
        if (!parent) {
          this.submenu.position.x =
            listElement.getBoundingClientRect().left +
            listElement.getBoundingClientRect().width +
            10;
          this.submenu.position.y = listElement.getBoundingClientRect().top;
        }
        this.submenu.items = item.children;
        this.submenuOpened = true;
      }, 200);
    }
  }
  protected closeAll() {
    this.opened = false;
    this.submenuOpened = false;
  }
  protected stackValue(item: Item) {
    const itemClone = cloneDeep(item);
    unset(itemClone, "children");
    this.stack.push(itemClone);
  }
}
