import { currencyFilter } from "@/filters/currency.filter";
import { BuiltReport, Report, ZReport } from "@/interfaces/report";
import { GroupCloseTill, GroupsZout } from "@/metadata/reports";
import { i18n } from "@/plugins/i18n";
import HttpService from "@/services/http.service";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import { AxiosRequestConfig } from "axios";
import { TablePagination } from "helix-vue-components";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import mergeWith from "lodash/mergeWith";
import omit from "lodash/omit";
import pick from "lodash/pick";
import reduce from "lodash/reduce";
import xor from "lodash/xor";
import Vue from "vue";
import { Dictionary } from "vuex";
import { messagesService } from "./messages.service";

enum SPECIAL_REPORTS {
  CLOSE_TILL = "close_till",
  ZOUT = "z_report",
  TRANSFER = "transfers",
  TRANSFER_VOIDED = "voided_inventory_transfers",
  REPORT_BY_EMPLOYEE = "report_by_employee",
  REPORT_BY_TIME = "report_by_time",
  // Adding retail sumaary for sale summary
  RETAIL_SUMMARY_REPORT = "retail_summary_report",
  RETAIL_SALES_BREAKDOWN = "retail_sales_breakdown"
}
class ReportsService extends HttpService {
  public ZReportKeys: string[] = [];
  public retailSalesBreakdownQuery = {
    per_page: 1,
    page: 1
  };
  protected nonPaginated: string[] = [
    "close_till",
    "z_report",
    "monthly_report",
    "retail_summary_report"
  ];

  public async getReport(
    category: string,
    type: string,
    filters?: any,
    pagination?: TablePagination
  ) {
    this.uri = ["/report", category, type].join("/");
    let paginated = true;
    const formattedFilters = mapValues(filters, value => {
      if (typeof value === "boolean") {
        return +value;
      }
      return value;
    });
    let query;
    query = { ...formattedFilters };
    if (this.nonPaginated.includes(type)) {
      paginated = false;
    } else {
      query = {
        ...query,
        per_page:
          (pagination && pagination.itemsPerPage) ||
          (type === "retail_sales_breakdown" &&
            formattedFilters!["q[breakdown_by]"] &&
            formattedFilters!["q[breakdown_by]"] !== "PRODUCT")
            ? this.retailSalesBreakdownQuery.per_page
            : this.query.per_page,
        page:
          (pagination && pagination.currentPage) ||
          (type === "retail_sales_breakdown" &&
            formattedFilters!["q[breakdown_by]"] &&
            formattedFilters!["q[breakdown_by]"] !== "PRODUCT")
            ? this.retailSalesBreakdownQuery.page
            : this.query.page
      };
    }
    const response = await this.get(query, paginated);
    forEach(response.data, (value, key) => {
      this.ZReportKeys = this.ZReportKeys.concat(key);
    });
    const build = await this.buildReferencedReport(
      response.data,
      type,
      paginated,
      formattedFilters
    );
    return build;
  }

  public async getExport(category: string, type: string, filters?: any) {
    try {
      this.uri = ["/report", category, type, "download"].join("/");
      const formattedFilters = mapValues(filters, value => {
        if (typeof value === "boolean") {
          return +value;
        }
        return value;
      });
      const response = await super.get({ ...formattedFilters }, false);

      const downloadUrl = window.URL.createObjectURL(
        new Blob([response], { type: "csv" })
      );
      const timestamp = fnsFormatDate(new Date(), "T");
      const download = document.createElement("a");
      download.setAttribute("href", downloadUrl);
      download.setAttribute("download", `${category}-${type}${timestamp}.csv`);
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
      return true;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }

  public async getReportCsv(category: string, type: string, filters?: any) {
    try {
      const uri = ["/report", category, type].join("/");
      const formattedFilters = mapValues(filters, value => {
        if (typeof value === "boolean") {
          return +value;
        }
        return value;
      });
      const configHeaders =
        category === "money" && ["close_till", "z_report"].includes(type)
          ? {
              "Content-Type": "application/xhtml+xml",
              Accept: "application/json,"
            }
          : {
              "Content-Type": "text/csv",
              Accept: "text/csv,"
            };
      const config: AxiosRequestConfig = {
        data: null,
        method: "GET",
        url: uri,
        responseType: "arraybuffer",
        params: formattedFilters,
        headers: configHeaders
      };
      await Vue.axios.get(uri, config).then(response => {
        const timestamp = fnsFormatDate(new Date(), "T");
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          category === "money" && ["close_till", "z_report"].includes(type)
            ? `${category}-${type}${timestamp}.xlsx`
            : `${category}-${type}${timestamp}.csv`;
        link.click();
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }

  public async getReportDat(
    category: string,
    type: string,
    filters?: Dictionary<any>
  ) {
    try {
      const uri = ["/report", category, type, "download"].join("/");
      const formattedFilters = mapValues(filters, value => {
        if (typeof value === "boolean") {
          return +value;
        }
        return value;
      });
      const configHeaders = {
        "Content-Type": "application/octet-stream",
        Accept: "application/octet-stream,"
      };
      const config: AxiosRequestConfig = {
        data: null,
        method: "GET",
        url: uri,
        responseType: "arraybuffer",
        params: formattedFilters,
        headers: configHeaders
      };
      await Vue.axios.get(uri, config).then(response => {
        const timestamp = fnsFormatDate(new Date(), FNS_DATE_FORMATS.DAT_FILE);
        const blob = new Blob([response.data], {
          type: "application/octet-stream"
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${timestamp}.dat`;
        link.click();
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }
  public async getReportPdf(category: string, type: string, filters?: any) {
    try {
      const uri = ["/report", category, type].join("/");
      const fileNames: { [key: string]: string } = {
        monthly_report: "OK Monthly Report",
        close_till: "Close Till Report",
        transfer: "Transfer Report",
        voided_inventory_transfers: "Voided Inventory Transfers",
        z_report: "Z Out Report",
        inventory_conversion: "Inventory Conversion Report",
        money_actions: "Money Actions"
      };
      const formattedFilters = mapValues(filters, value => {
        if (typeof value === "boolean") {
          return +value;
        }
        return value;
      });
      const configHeaders = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      const config: AxiosRequestConfig = {
        data: null,
        method: "GET",
        url: uri,
        responseType: "arraybuffer",
        params: formattedFilters,
        headers: configHeaders
      };
      await Vue.axios.get(uri, config).then(response => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNames[type];
        if (
          (formattedFilters.from_date || formattedFilters.start_date) &&
          (formattedFilters.to_date || formattedFilters.end_date) !== null
        ) {
          const date = `${fnsFormatDate(
            new Date(formattedFilters.from_date || formattedFilters.start_date),
            FNS_DATE_FORMATS.BARS_DEFAULT
          )}_${fnsFormatDate(
            new Date(formattedFilters.to_date || formattedFilters.end_date),
            FNS_DATE_FORMATS.BARS_DEFAULT
          )}`;

          link.download += `-${date}`;
        }
        link.click();
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return;
    }
  }

  public setPaginationQuery(pagination: TablePagination, type?: string) {
    if (type === "retail_sales_breakdown") {
      this.retailSalesBreakdownQuery.per_page = pagination.itemsPerPage;
      this.retailSalesBreakdownQuery.page = pagination.currentPage;
    }
    this.query.per_page = pagination.itemsPerPage;
    this.query.page = pagination.currentPage;
  }

  public buildMoneyReports(report: Report) {
    const ZReports: ZReport = {
      safe_report: { columns: [], rows: [] },
      till_report: { columns: [], rows: [] },
      retail_report: { columns: [], rows: [] }
    };
    forEach(report, (value: any, key: string) => {
      const columns = value.columns.map((r: string) => [r].join("_"));
      const rows: any = [];
      const builtRow: { [key: string]: any } = {};
      value.rows.forEach((item: any, i: number) => {
        if (item.length && isArray(item)) {
          const a: object[] = [];
          let b: object = {};
          item.forEach((field: string | number, j: number) => {
            if (typeof field !== "object") {
              let builtField = field;
              if (/true/i.test(String(field))) {
                builtField = i18n.t("yes").toString();
              }
              if (/false/i.test(String(field))) {
                builtField = i18n.t("no").toString();
              }
              b = { ...b, [columns[j]]: builtField };
            } else {
              a.push(field);
              if (a.length) {
                builtRow[columns[i]] = a;
              }
            }
          });
          if (!isEmpty(b)) {
            rows.push(b);
          }
        } else if (typeof item === "object") {
          const a: object[] = [];
          Object.entries(item).forEach(element => {
            a.push({ [element[0]]: element[1] });
          });
          builtRow[columns[i]] = a;
        } else {
          builtRow[columns[i]] = item;
        }
      });
      if (Object.keys(builtRow).length) {
        rows.push(builtRow);
      }
      ZReports[key] = { columns, rows };
    });
    return ZReports;
  }
  public formatRetailSalesBreakdown(row: string | number, index: number) {
    if ((index > 2 && index < 8) || (index > 9 && index < 12)) {
      row = currencyFilter(row);
    }
    if (index > 11 || index === 8) {
      row = row + " %";
    }
    return row;
  }

  /**
   * Builds referenced report
   * @param report
   * @returns BuiltReport if it's a normal Report or an object with different
   * reports if it's a ZReport
   */
  private buildReferencedReport(
    dataReport: Report,
    type: string,
    paginated: boolean,
    formattedFilters?: any
  ) {
    let specialReport: { [key: string]: (report: Report) => any } = {
      [SPECIAL_REPORTS.ZOUT]: this.groupZOutReports,
      [SPECIAL_REPORTS.RETAIL_SUMMARY_REPORT]: this
        .getRetailSalesSummaryReports,
      [SPECIAL_REPORTS.CLOSE_TILL]: this.groupCloseTillsReport,
      [SPECIAL_REPORTS.TRANSFER &&
      SPECIAL_REPORTS.TRANSFER_VOIDED]: async () => ({
        reports: dataReport.reports,
        pagination: await super.getPagination(true)
      }),
      [SPECIAL_REPORTS.REPORT_BY_EMPLOYEE]: async () => ({
        report: dataReport,
        pagination: null,
        reportType: type
      }),
      [SPECIAL_REPORTS.REPORT_BY_TIME]: async () => ({
        report: dataReport,
        pagination: await super.getPagination(true),
        reportType: type
      })
    };
    if (
      formattedFilters!["q[breakdown_by]"] &&
      formattedFilters!["q[breakdown_by]"] !== "PRODUCT"
    ) {
      specialReport = {
        ...specialReport,
        [SPECIAL_REPORTS.RETAIL_SALES_BREAKDOWN]: async () => ({
          reports: dataReport,
          pagination: await super.getPagination(true)
        })
      };
    }
    if (Object.keys(specialReport).includes(type)) {
      const getCurrentReport = specialReport[type];
      return getCurrentReport(dataReport);
    }

    if (dataReport.columns) {
      if (type === "inventory_conversion") {
        dataReport.rows = this.modifyInventoryConversion(dataReport);
      }
      return this.setComunReport(dataReport, paginated, type);
    } else {
      const unionRows = new Set();
      const unionColumns = new Set();
      dataReport.reports.forEach(r => {
        unionRows.add([...r.rows]);
        unionColumns.add([...r.columns]);
      });
      dataReport.rows = [...unionRows] as string[][];
      dataReport.columns = [...unionColumns] as string[];
      return this.setComunReport(dataReport, paginated);
    }
  }

  private getMoneyReport(
    group: ZReport,
    report: Report,
    extraArrayOmit: string[] = []
  ) {
    const fieldsRetailReport = (row: { [key: string]: any }) => {
      if (
        row.total_retail_sales_by_tax_category &&
        row.total_retail_sales_by_tax_category.length
      ) {
        row.d_total_retail_sales_by_tax_category =
          row.total_retail_sales_by_tax_category;
      }
      if (
        row.total_retail_sales_by_payment_methods &&
        row.total_retail_sales_by_payment_methods.length
      ) {
        row.e_total_retail_sales_by_payment_methods = Object.assign(
          {},
          ...row.total_retail_sales_by_payment_methods
        );
      }
      return row;
    };

    return reduce(
      this.buildMoneyReports(report),
      (result: ZReport, value, key) => {
        if (!Object.keys(group).includes(key)) {
          result[key] = value;
        } else {
          const fieldsToUse = group[key].rows[0];
          const itemsOmit = Object.values(fieldsToUse).flat();
          result[key] = {
            columns: xor(value.columns, itemsOmit),
            rows: value.rows.map(row => {
              if (key === "retail_report") {
                row = fieldsRetailReport(row);
              }
              // omit keys used in groups if fieldsToUse
              return omit(
                // merge keys fieldsToUse with values of row
                mergeWith(
                  row,
                  fieldsToUse,
                  (fieldRow, fieldToUse) =>
                    (fieldToUse && pick(row, fieldToUse)) || null
                ),
                itemsOmit.concat(extraArrayOmit)
              );
            })
          };
        }
        return result;
      },
      {}
    );
  }

  private groupCloseTillsReport = (report: Report): ZReport => {
    return this.getMoneyReport(GroupCloseTill, report);
  };

  private groupZOutReports = (report: Report): ZReport => {
    return this.getMoneyReport(GroupsZout, report, [
      "total_retail_sales_by_tax_category",
      "total_retail_sales_by_payment_methods"
    ]);
  };

  private getRetailSalesSummaryReports = (report: Report): Report => {
    return report;
  };

  private modifyInventoryConversion(report: Report) {
    report.rows = Object.values(report.rows);
    const newRows: any[] = [];
    report.rows.forEach(row => {
      const tempRow: Array<{ [x: string]: string }> = [];
      row = Object.values(row);
      row.map((rowIndex: any) => {
        const length = rowIndex.length;
        for (let len = 0; len < length; len++) {
          const index = tempRow.findIndex(
            (tempRowIndex: { original_batch_id?: string }) =>
              tempRowIndex.original_batch_id === rowIndex[len].original_batch_id
          );
          if (index === -1) {
            tempRow.push(rowIndex[len]);
          } else {
            tempRow[index].converted_amount =
              +tempRow[index].converted_amount.slice(0, -1) +
              +rowIndex[len].converted_amount.slice(0, -1) +
              tempRow[index].converted_amount.slice(-1);
          }
        }
      });
      newRows.push(...tempRow);
    });
    return newRows;
  }

  private async setComunReport(
    report: Report,
    paginated: boolean,
    type?: string
  ) {
    const build: BuiltReport = {
      columns: [],
      rows: [],
      pagination: paginated ? await super.getPagination(true) : null,
      reports: report.reports,
      filter: report.filter
    };
    build.columns = report.columns.map(r => [r].join("_"));
    if (typeof report.rows === "object") {
      report.rows = Object.values(report.rows);
    }
    report.rows.forEach(row => {
      if (typeof row === "object") {
        row = Object.values(row);
        row.forEach(item => (Array.isArray(item) ? item[0] : item));
      }
      const builtRow: { [key: string]: any } = {};
      row.forEach((field, index) => {
        let builtField = field;
        if (/true/i.test(String(field))) {
          builtField = "Yes";
        }
        if (/false/i.test(String(field))) {
          builtField = "No";
        }
        if (/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/g.test(String(field))) {
          builtField = fnsFormatDate(
            new Date(field),
            FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
          );
        }
        if (Array.isArray(field)) {
          builtField = field.join("\n");
        }
        builtRow[build.columns[index]] = builtField;
        if (type === "retail_sales_breakdown") {
          builtRow[build.columns[index]] = this.formatRetailSalesBreakdown(
            builtRow[build.columns[index]],
            index
          );
        }
      });
      build.rows.push(builtRow);
    });
    return build;
  }
}

export default new ReportsService();
