import { Report } from "@/interfaces/report";
import { Pagination, Table, TableColumn } from "element-ui";
import { TablePagination } from "helix-vue-components";
import size from "lodash/size";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TimeclockReport.template.vue";

interface HeaderSort {
  order: "ascending" | "descending";
  prop: string;
}
@Component({
  mixins: [Template],
  components: {
    Table,
    TableColumn,
    Pagination
  }
})
export default class TimeclockReportComponent extends Vue {
  @Prop({ required: true }) public data!: {
    report: Report;
    pagination: TablePagination | null;
    reportType: "report_by_employee" | "report_by_time";
  } | null;

  @Prop({ default: false })
  public loading!: boolean;

  public currentSort: HeaderSort | object = {};

  public reportTypes = {
    employee: "report_by_employee",
    time: "report_by_time"
  };

  public sortChange(data: HeaderSort) {
    this.currentSort = data;
    this.$emit("sortChange", data);
  }

  public onSizeChange(rows: number) {
    this.$emit("sizeChange", rows);
  }

  public pageChange(page: number) {
    this.$emit("paginate", page);
  }

  public isEmpty(data: object) {
    return !!!size(data);
  }
}
