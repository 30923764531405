import {
  TablePagination,
  TablePaginationComponent
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReportRetailSalesBreakDown.template.vue";
@Component({
  mixins: [Template],
  components: {
    TablePaginationComponent
  }
})
export default class ReportRetailSalesBreakDown extends Vue {
  @Prop() public data!: {};
  @Prop({ default: {} }) public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;

  public changePagination(pagination: TablePagination) {
    this.$emit("onChangePagination", pagination);
  }
}
