import {
  TablePagination,
  TablePaginationComponent
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./TransferReport.template.vue";

interface TransferReportData {
  [key: string]: {
    columns: any[];
    rows: any[];
    balance: any[];
    totals: any[];
    name: any[];
  };
}
@Component({
  mixins: [Template],
  components: {
    TablePaginationComponent
  }
})
export default class TransferReportComponent extends Vue {
  @Prop({ default: () => [] })
  public data!: TransferReportData[];
  @Prop({ default: {} }) public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;

  public changePagination(pagination: TablePagination) {
    this.$emit("onChangePagination", pagination);
  }
}
