var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-report-container"},_vm._l((_vm.reports),function(value,key,i){return _c('div',{key:i},[(value.data.length && !_vm.isEmpty(value.data[0]))?_c('p',{staticClass:"my-4 font-weight-black z-report-container__title"},[_vm._v("\n      "+_vm._s(_vm.$t(key))+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"z-report-container__table-report"},_vm._l((value.data),function(data,j){return _c('div',{key:j,staticClass:"my-4"},[(key === 'till_report')?_c('div',{staticClass:"z-report-container__table-report--till pa-2 text-background"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("till_asignment")))]),_vm._v(" "),_c('div',{staticClass:"z-report-container__table-report--till__row"},_vm._l((_vm.getData(data)),function(val,key,n){return _c('div',{key:(key + "-" + n)},[(key === 'user')?_c('div',{staticClass:"user-text"},[_vm._v(_vm._s(val))]):_c('div',{staticClass:"date-text px-1"},[_vm._v("\n                "+_vm._s(("" + (key === "start_date"
                      ? ("From: " + val + " ")
                      : " " + " To: " + val)))+"\n              ")])])}),0)]):_vm._e(),_vm._v(" "),_vm._l((_vm.getOrderedData(data, key)),function(val,dataKey,index){return _c('div',{key:index,class:{ 
            'z-report-container__table-report--row': Object.keys(val).length,
            'z-report-container__table-report--row--wrapper': typeof val === 'object'
          }},[(_vm.conditionalHeader(dataKey) && Object.keys(val).length)?_c('div',{staticClass:"pa-2 font-weight-black z-report-container__table-report--row--text",class:{
              'text-background':
                typeof val === 'object'
            }},[_vm._v("\n            "+_vm._s(_vm.getTextHeader(dataKey,value.headers))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.isPlainObject(val))?_vm._l((val),function(objVal,objKey,k){return _c('div',{key:k,staticClass:"z-report-container__table-report--row--text",class:{
                'z-report-container__table-report--row--payment': _vm.isPlainObject(
                  objVal
                ),
                ' pa-1 z-report-container__table-report--row--text--object': !_vm.isPlainObject(
                  objVal
                )
              }},[_c('div',{staticClass:"font-weight-bold",class:{
                  'pa-1 z-report-container__table-report--row--payment__title': _vm.isPlainObject(
                    objVal
                  )
                }},[_vm._v("\n                "+_vm._s(_vm.$t(objKey))+"\n              ")]),_vm._v(" "),(_vm.isPlainObject(objVal))?_vm._l((objVal),function(ov,ok,l){return _c('div',{key:l,staticClass:"pa-1 z-report-container__table-report--row--payment__info"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(ok)))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatNumber(ov)))])])}):_c('div',[_vm._v(_vm._s(_vm.formatNumber(objVal)))])],2)}):(_vm.isArray(val))?_vm._l((val),function(arr,k){return _c('div',{key:k},_vm._l((arr),function(objVal,objKey,h){return _c('div',{key:h,staticClass:"pa-1 z-report-container__table-report--row--text"},[(!_vm.isPlainObject(objVal))?_c('div',{staticClass:"z-report-container__table-report--row--text--object"},[(h === 0)?_c('div',{staticClass:"font-weight-bold z-report-container__table-report--row--title"},[_vm._v("\n                    "+_vm._s(objVal)+"\n                  ")]):[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(objKey)))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatNumber(objVal)))])]],2):_c('div',[_c('div',{staticClass:"font-weight-bold z-report-container__table-report--row--title"},[_vm._v(_vm._s(_vm.$t(objKey)))]),_vm._v(" "),_vm._l((objVal),function(objSubVal,objSubKey,m){return _c('div',{key:m,staticClass:"pt-1 pb-1 z-report-container__table-report--row--payment__info z-report-container__table-report--row--text--object"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(objSubKey)))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.formatNumber(objSubVal)))])])})],2)])}),0)}):(
              dataKey !== 'user' &&
                dataKey !== 'start_date' &&
                dataKey !== 'end_date'
            )?_c('div',{staticClass:"pa-2 z-report-container__table-report--row--text z-report-container__table-report--row--text-value"},[_vm._v("\n            "+_vm._s(val)+"\n          ")]):_vm._e()],2)})],2)}),0)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }