import { Rule } from "@/interfaces/location";
import { Callback } from "@/types/types";
import {
  HelixDatePickerOptions,
  HelixDatePickerTypes
} from "helix-vue-components";
import { TranslateResult } from "vue-i18n";

type FieldEventCallback = (...arg: any[]) => void;

/**
 * enum to know the component to render by the field type
 */
export enum FieldType {
  string = "TextFieldComponent",
  email = "EmailFieldComponent",
  number = "NumericFieldComponent",
  integer = "IntegerFieldComponent",
  float = "NumericFieldComponent",
  date = "DateFieldComponent",
  image = "ImageFieldComponent",
  takePicture = "TakePictureFieldComponent",
  dropdown = "DropdownFieldComponent",
  check = "CheckboxFieldComponent",
  radio = "RadioFieldComponent",
  autocomplete = "AutoCompleteFieldComponent",
  editor = "EditorFieldComponent",
  toggle = "ToggleFieldComponent",
  time = "TimeFieldComponent",
  pictureField = "ImageOrTakePhotoFieldComponent",
  checkMultiple = "CheckboxMultipleFieldComponent",
  file = "FileFieldComponent",
  textarea = "TextareaFieldComponent",
  limitField = "LimitFieldComponent",
  spacer = "SpacerFieldComponent"
}

/**
 * describe the property in a form
 */
export interface PropertyField {
  type: string;
  label: string;
  modelField: string;
  validationRules?: string | null;
  event?: FieldEventCallback;
  options?: FieldOptions;
  colspan?: number;
}

export interface FieldOptions {
  relatedModel?: string;
  placeholder?: string | TranslateResult;
  getData?: (...arg: any[]) => Promise<any[]> | any[];
  itemText?: string;
  itemValue?: string;
  paginated?: boolean;
  returnObject?: boolean;
  label?: string | TranslateResult;
  change?: Callback;
  disabled?: boolean | Callback;
  items?: any[] | Promise<any[]>;
  depend?: string;
  multiple?: boolean;
  mask?: string;
  align?: string;
  value?: string;
  options?: object[];
  rows?: number;
  draggable?: boolean;
  noResize?: boolean;
  accept?: string;
  visible?: Callback;
  solo?: boolean;
  flat?: boolean;
  "background-color"?: string;
  rules?: Rule[];
  ignoresPagination?: boolean;
  max?: string;
  pickerOptions?: Partial<HelixDatePickerOptions>;
  pickerType?: HelixDatePickerTypes;
}

/**
 * group form field
 */
export interface FieldsGroup {
  title: string | TranslateResult | null;
  tab?: TranslateResult | null;
  fields: PropertyField[];
  isCheckable?: boolean;
  images?: {
    position: string;
    fields: PropertyField[];
  };
}

/**
 * button form
 */
export interface Button {
  class: string;
  event: FieldEventCallback;
  disable: boolean;
  text?: string;
  type: string;
}

/**
 * default form object
 */
export interface Form {
  columns: number;
  fields: FieldsGroup[];
  tabs?: TranslateResult[] | null;
  buttons?: Button[];
  submitEvent?: string;
  description?: string;
  title?: string;
}
