import {
  TablePagination,
  TablePaginationComponent
} from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./VoidedTransferReport.template.vue";
@Component({
  mixins: [Template],
  components: {
    TablePaginationComponent
  }
})
export default class VoidedTransferReportComponent extends Vue {
  @Prop({ default: {} }) public data!: Array<{
    [key: string]: {
      columns: any[];
      rows: any[];
      voided: any[];
      totals: any[];
      name: any[];
    };
  }>;
  @Prop({ default: {} }) public pagination!: TablePagination;
  @Prop({ default: false })
  public loading!: boolean;

  public changePagination(pagination: TablePagination) {
    this.$emit("onChangePagination", pagination);
  }
  public mounted() {
    if (this.pagination.from === null && this.pagination.to === null) {
      this.pagination.from = 1;
      this.pagination.to = 1;
      this.pagination.totalItems = 1;
    }
  }
}
