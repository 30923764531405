import { MultiLevelSelectComponent } from "@/components/sharedComponents/MultiLevelSelectField/MultiLevelSelectField.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { formatNumber } from "@/filters/currency.filter";
import { Location } from "@/interfaces/location";
import {
  BuiltReport,
  Report,
  TableSpanObject,
  TimeclockReport,
  ZReport
} from "@/interfaces/report";
import { RetailSettings } from "@/interfaces/retailSettings";
import reportsService from "@/services/reports.service";
import { Callback, PageNavAction } from "@/types/types";
import { Pagination, Table, TableColumn } from "element-ui";
import {
  ActionSubheader,
  BooleanCheck,
  TablePagination
} from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import last from "lodash/last";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import orderBy from "lodash/orderBy";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { ReportsFiltersModalComponent } from "./../ReportFilters/ReportFilters.modal";
import { registeredFormFilters } from "./RegisteredFilters";
import OverviewASAP from "./ReportASAP/OverviewASAP.component";
import Template from "./ReportList.template.vue";
import ReportModalComponent from "./ReportModal.component";
import ReportRetailSalesBreakDown from "./ReportRetailSalesBreakDown/ReportRetailSalesBreakDown.component";
import ReportsalesSummary from "./ReportSalesSummary/ReportSalesSummary.component";
import {
  ReportCategory,
  ReportCategoryChildren,
  reportsCategories
} from "./reportsCategories.mock";
import TransferReportComponent from "./ReportTransferReport/TransferReport.component";
import VoidedTransferReportComponent from "./ReportVoidedInventoryTransfer/VoidedTransferReport.component";
import ReportZOut from "./ReportZOut/ReportZOut.component";
import TimeclockReportComponent from "./TimeclockReport/TimeclockReport.component";

@Component({
  mixins: [Template],
  components: {
    Table,
    TableColumn,
    Pagination,
    "z-out-report": ReportZOut,
    "transfer-report": TransferReportComponent,
    "voided-transfer-report": VoidedTransferReportComponent,
    "timeclock-report": TimeclockReportComponent,
    "overview-asap": OverviewASAP,
    "sales-summary": ReportsalesSummary,
    "retail-sales-breakdown": ReportRetailSalesBreakDown
  }
})
@ResetService(reportsService)
export default class ReportList extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("changeTitle", { namespace: "MegaMenuModule" })
  public changeTitleAction!: Callback;
  @Action("changeSubTitle", { namespace: "MegaMenuModule" })
  public changeSubTitleAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("changeIcon", { namespace: "MegaMenuModule" })
  public changeIcon!: Callback;
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  public retailSettings!: RetailSettings;
  public loading = false;
  public orderInformation: any[] = [];
  public selectedReport: Array<{ [key: string]: any; value: string }> = [];
  public appliedFilters: { [key: string]: any } = {};
  public loadingExport = false;
  public mocked: ReportCategory[] = [];
  public printDisabled = true;
  public fetchedReport: string | null = null;
  public asapReport: boolean = false;
  public asapReportGenerated: boolean = false;
  public asapReportData: {
    headers: Array<{ text: string | {}; value: string }>;
    data: string[][];
    pagination: TablePagination | null;
  } = {
    headers: [],
    data: [],
    pagination: null
  };
  public isCustomerList = false;
  public isInventoryConversion = false;
  public spanArr: number[] = [];
  public productSpanArr: number[] = [];
  public styleSpanArr: number[] = [];
  public loadingSort = false;
  public spanObject: TableSpanObject = {
    rowIndex: 0,
    columnIndex: 0
  };
  protected get exportDisableStatus() {
    const lastChild = last(this.selectedReport) as ReportCategoryChildren;
    if (lastChild) {
      if (
        this.selectedReport[0].value === "prescription" &&
        this.selectedReport[1].value === "asap" &&
        this.hasPermission(policyList.printReports) &&
        !this.asapReportGenerated
      ) {
        return true;
      }
      return (
        !this.hasPermission(policyList.exportReports) ||
        !this.hasPermission(lastChild.viewPolicy) ||
        this.loadingExport
      );
    }
    return true;
  }

  protected get printDisableStatus() {
    const lastChild = last(this.selectedReport) as ReportCategoryChildren;
    if (lastChild) {
      return (
        !this.hasPermission(policyList.printReports) ||
        !this.hasPermission(lastChild.viewPolicy) ||
        this.selectedReport[1].value === "voided_inventory_transfers" ||
        this.loadingExport ||
        this.printDisabled
      );
    }
    return true;
  }

  protected get emptyTextData() {
    return this.selectedReport[1].value === "asap" &&
      this.report.data.length === 0
      ? this.$t("prescription_report_page_no_data")
      : this.$t("no_data");
  }

  public get getSelectedReportCategory() {
    return (this.selectedReport.length && this.selectedReport[0].value) || null;
  }

  public get showReport() {
    return !!(
      this.fetchedReport &&
      this.selectedReport.length > 1 &&
      this.selectedReport[1].value === this.fetchedReport
    );
  }

  protected get metaSearchFilters() {
    if (this.selectedReport[1].value === "asap") {
      if (!this.appliedFilters.report_date) {
        this.appliedFilters.report_date = new Date(
          new Date().setHours(0, 0, 0)
        ).toISOString();
        this.appliedFilters.report_generation_time = new Date().toISOString();
      }
    }
    // if no filters applied we will get today data for 'Inventory' & 'Retail' section
    if (
      !Object.entries(this.appliedFilters).length &&
      (this.selectedReport[0].value === "inventory" ||
        (this.selectedReport[0].value === "retail" &&
          this.selectedReport[1].value !== "retail_sales_breakdown"))
    ) {
      this.appliedFilters.created_at_greater_than_or_equal = new Date(
        new Date().setHours(0, 0, 0)
      ).toISOString();
    }

    return mapKeys(this.appliedFilters, (value, key) => {
      if (
        [
          "from_date",
          "to_date",
          "start_date",
          "end_date",
          "product_id",
          "user_id",
          "order_by"
        ].includes(key) ||
        (this.selectedReport[1] &&
          this.selectedReport[1].value === "retail_sales_breakdown" &&
          key !== "breakdown_by")
      ) {
        return key;
      } else {
        key = key.replace(/-/g, ".");
        return `q[${key}]`;
      }
    });
  }
  public get actions(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-save",
        action: this.export,
        vuetifyProps: () => ({
          disabled: this.exportDisableStatus,
          fab: true,
          small: true
        })
      },
      {
        icon: "fal fa-print",
        action: this.reportpdf,
        vuetifyProps: () => ({
          disabled: this.printDisableStatus,
          fab: true,
          small: true
        })
      }
    ];
  }
  protected report: {
    headers: any[];
    data: any[];
    pagination: TablePagination | null;
  } = {
    headers: [],
    data: [],
    pagination: null
  };
  protected zreports: { [key: string]: { headers: any[]; data: any[] } } = {
    retail_report: { headers: [], data: [] },
    safe_report: { headers: [], data: [] },
    till_report: { headers: [], data: [] }
  };
  protected transactionSummaryReport: Report | ZReport | null = null;
  protected transferReport: Report | null = null;
  protected voidedTransferReport: Report | null = null;
  protected timeClockReport: TimeclockReport | null = null;
  protected retailSalesBreakDown: Report | null = null;

  protected reportTypes = [
    "monthly_report",
    "close_till",
    "transfer",
    "voided_inventory_transfers",
    "z_report",
    "metrc",
    "inventory_conversion",
    "money_actions",
    "retail_summary_report"
  ];

  constructor() {
    super();
    this.changeTitleAction("reports");
    this.changeSubTitleAction("");
    this.changeIcon(
      require("@/assets/images/icon_primary_menu_reports@2x.png")
    );
  }

  public onChangePagination(pagination: TablePagination) {
    this.report.pagination = pagination;
    this.getReport();
  }

  public transfersPaginate(pagination: TablePagination) {
    if (this.transferReport) {
      this.transferReport.pagination = pagination;
      reportsService.setPaginationQuery(this.transferReport.pagination);
      this.getReport();
    }
  }

  public voidedTransfersPaginate(pagination: TablePagination) {
    if (this.voidedTransferReport) {
      this.voidedTransferReport.pagination = pagination;
      reportsService.setPaginationQuery(this.voidedTransferReport.pagination);
      this.getReport();
    }
  }

  public retailSalesBreakDownPaginate(pagination: TablePagination) {
    if (this.retailSalesBreakDown) {
      this.retailSalesBreakDown.pagination = pagination;
      reportsService.setPaginationQuery(
        this.retailSalesBreakDown.pagination,
        "retail_sales_breakdown"
      );
      this.getReport();
    }
  }

  public timeclockSortChange(data: {
    order: "ascending" | "descending";
    prop: string;
  }) {
    const filters = cloneDeep(this.appliedFilters);
    filters.order_by = `${data.order === "descending" ? "-" : ""}${data.prop}`;
    this.appliedFilters = filters;
    this.timeclockPaginate(1);
  }

  public timeclockPaginate(page: number) {
    if (this.timeClockReport && this.timeClockReport.pagination) {
      this.timeClockReport.pagination.currentPage = page;
      reportsService.setPaginationQuery(this.timeClockReport.pagination);
    }
    this.getReport();
  }

  public timeclockSize(rows: number) {
    this.timeClockReport!.pagination!.itemsPerPage = rows;
    this.timeclockPaginate(1);
  }

  protected async export() {
    if (
      !this.report.data.length &&
      this.selectedReport[0].value === "prescription" &&
      this.selectedReport[1].value === "asap"
    ) {
      this.showReportModal(
        this.$t(
          "There were no Prescriptions for the timeframe selected, a zero report will be generated for this timeframe."
        ).toString(),
        "normal",
        "export"
      );
      return;
    }
    this.loadingExport = true;
    if (this.reportTypes.includes(this.selectedReport[1].value)) {
      await reportsService.getReportCsv(
        this.selectedReport[0].value,
        this.selectedReport[1].value,
        this.metaSearchFilters
      );
    } else if (
      this.selectedReport[1].value === "asap" &&
      this.selectedReport[0].value === "prescription"
    ) {
      reportsService.getReportDat(
        this.selectedReport[0].value,
        this.selectedReport[1].value,
        this.metaSearchFilters
      );
    } else {
      await reportsService.getExport(
        this.selectedReport[0].value,
        this.selectedReport[1].value,
        this.metaSearchFilters
      );
    }
    this.loadingExport = false;
  }

  protected async reportpdf() {
    if (
      !this.report.data.length &&
      this.selectedReport[0].value === "prescription"
    ) {
      this.showReportModal(
        this.$t("There is no data to print").toString(),
        "fit",
        "print"
      );
      return;
    }
    this.loadingExport = true;
    if (
      this.selectedReport[0].value === "prescription" &&
      this.selectedReport[1].value === "asap"
    ) {
      const filters = {
        ...this.metaSearchFilters,
        no_pagination: true
      };
      reportsService
        .getReport(
          this.selectedReport[0].value,
          this.selectedReport[1].value,
          filters
        )
        .then((response: Report) => {
          if (response) {
            this.asapReportData.headers = this.mapElHeaders(response);
            this.asapReportData.data = response.rows;
            this.asapReport = true;
          }
        });
    } else {
      await reportsService.getReportPdf(
        this.selectedReport[0].value,
        this.selectedReport[1].value,
        this.metaSearchFilters
      );
    }

    this.loadingExport = false;
  }

  protected clearAll(stack: any) {
    this.selectedReport = stack;
    this.appliedFilters = {};
  }

  protected async showFilters() {
    const filterKey = this.selectedReport.map(r => r.value).join(".");
    const appliedFilters = (await this.$modals.load(
      ReportsFiltersModalComponent,
      {
        size: "normal"
      },
      {
        form: registeredFormFilters[filterKey],
        selectedReport: this.selectedReport
      }
    )) as { [key: string]: any };
    if (
      this.selectedReport[0].value === "prescription" &&
      this.selectedReport[1].value === "asap"
    ) {
      appliedFilters.report_date = new Date(
        new Date(appliedFilters.report_date).setHours(0, 0, 0)
      ).toISOString();
    }
    this.appliedFilters = appliedFilters;
  }

  protected getTransferReport(report: Report | ZReport) {
    this.transferReport = report as Report;
  }

  protected getVoidedTransferReport(report: Report | ZReport) {
    this.voidedTransferReport = report as Report;
  }

  protected getRetailSalesBreakDown(report: Report | ZReport) {
    if (
      this.appliedFilters.breakdown_by &&
      this.appliedFilters.breakdown_by !== "PRODUCT" &&
      this.selectedReport[1].value === "retail_sales_breakdown"
    ) {
      this.retailSalesBreakDown = report as Report;
    }
  }

  protected getTimeclockReport(report: Report | ZReport) {
    this.timeClockReport = (report as unknown) as TimeclockReport;
    this.timeClockReport.headers = this.mapElHeaders(
      ((report as unknown) as TimeclockReport).report
    ).filter(h => h.value !== "user_id");
  }

  protected mapElHeaders(report: BuiltReport) {
    return report.columns.map(c => {
      return {
        text: this.$t(
          [
            "reports_group",
            this.selectedReport[0].value,
            this.selectedReport[1].value,
            !isNaN(+c.replace("field", "")) ? "field" : c
          ].join("."),
          {
            value: !isNaN(+c.replace("field", "")) ? c.replace("field", "") : ""
          }
        ),
        value: c
      };
    });
  }
  protected getCommonReport(report: BuiltReport) {
    let filledByPharmacistColumnRemoved = false;
    if (this.selectedReport[1].value === "sales") {
      if (!this.retailSettings.pharmacy_mode_enabled) {
        report.columns.splice(6, 1);
        filledByPharmacistColumnRemoved = true;
      }
      if (
        !this.retailSettings.pharmacy_mode_enabled ||
        this.currentLocation.state_id !== 31
      ) {
        filledByPharmacistColumnRemoved
          ? report.columns.splice(8, 1)
          : report.columns.splice(9, 1);
      }
    }
    this.report.headers = this.mapElHeaders(report);
    this.report.data =
      // map for validation of decimals in the sales report
      this.selectedReport[1].value === "sales"
        ? (report as BuiltReport).rows.map(r => ({
            ...r,
            sub_total: (r.sub_total && r.sub_total.toFixed(2)) || "0.00",
            tax_collected:
              (r.tax_collected && r.tax_collected.toFixed(2)) || "0.00",
            tax_collected_sum:
              (r.tax_collected_sum && r.tax_collected_sum.toFixed(2)) || "0.00",
            total_sum: (r.total_sum && r.total_sum.toFixed(2)) || "0.00",
            total: (r.total && r.total.toFixed(2)) || "0.00",
            sale_price: (r.sale_price && r.sale_price.toFixed(2)) || "0.00",
            overall_tax_collected:
              (r.overall_tax_collected && r.overall_tax_collected.toFixed(2)) ||
              "0.00"
          }))
        : (report as BuiltReport).rows;
    const selectedReportIndex = this.selectedReport.findIndex(
      item => item.value === "customer_list"
    );
    if (selectedReportIndex !== -1) {
      this.isCustomerList = true;
    } else {
      this.isCustomerList = false;
    }

    const selectedReportIndexInvCon = this.selectedReport.findIndex(
      item => item.value === "inventory_conversion"
    );
    if (selectedReportIndexInvCon !== -1) {
      this.inventoryConversionData();
      this.isInventoryConversion = true;
    } else {
      this.isInventoryConversion = false;
    }
    if (this.selectedReport[1].value === "retail_sales_breakdown") {
      this.sortChange({ prop: "product_name", order: "ascending" });
    }
    if (this.selectedReport[1].value === "current_inventory") {
      this.report.data.map(item => {
        if (item.usable_weight && item.usable_weight.includes("mg")) {
          item.usable_weight = `${parseFloat(item.usable_weight) / 1000} g`;
          return item;
        }
      });
    }
  }
  protected objectSpanMethod(spanObject: TableSpanObject) {
    if (this.isInventoryConversion) {
      if (spanObject.columnIndex >= 4 && spanObject.columnIndex <= 6) {
        const row = this.productSpanArr[spanObject.rowIndex];
        const col = row > 0 ? 1 : 0;
        return {
          rowspan: row,
          colspan: col
        };
      }
      if (!(spanObject.columnIndex > 6 && spanObject.columnIndex <= 15)) {
        const row = this.spanArr[spanObject.rowIndex];
        const col = row > 0 ? 1 : 0;
        return {
          rowspan: row,
          colspan: col
        };
      }
    }
  }
  protected inventoryConversionData() {
    const spanArr: number[] = [];
    const productSpanArr: number[] = [];
    let pos = 0;
    let productPos = 0;

    for (let i = 0; i < this.report.data.length; i++) {
      if (i === 0) {
        spanArr.push(1);
        productSpanArr.push(1);
      } else {
        if (
          this.report.data[i].conversion_id ===
          this.report.data[i - 1].conversion_id
        ) {
          spanArr[pos] = spanArr[pos] + 1;
          spanArr.push(0);
        } else if (
          this.report.data[i].conversion_id !==
          this.report.data[i - 1].conversion_id
        ) {
          spanArr.push(1);
          pos = i;
        }

        if (
          this.report.data[i].conversion_id ===
            this.report.data[i - 1].conversion_id &&
          this.report.data[i].original_product ===
            this.report.data[i - 1].original_product
        ) {
          productSpanArr[productPos] = productSpanArr[productPos] + 1;
          productSpanArr.push(0);
        } else {
          productSpanArr.push(1);
          productPos = i;
        }
      }
    }

    this.spanArr = spanArr;
    this.productSpanArr = productSpanArr;
    const styleSpanArr: number[] = cloneDeep(spanArr);
    for (let i = 0; i < styleSpanArr.length; i++) {
      if (styleSpanArr[i] > 0) {
        styleSpanArr[styleSpanArr[i] - 1 + i] = -1;
      }
    }
    this.styleSpanArr = styleSpanArr;
  }
  protected cellClassName({ rowIndex, columnIndex }: TableSpanObject) {
    if (this.report.data[rowIndex].product_name === "Totals") {
      return "tableBold";
    }
    if (this.selectedReport[1].value === "retail_sales_breakdown") {
      return "tablePadding";
    }
    if (this.isInventoryConversion) {
      if (
        !(
          columnIndex < 4 ||
          (columnIndex > 15 ||
            ((this.spanArr[rowIndex] === 0 &&
              this.spanArr[rowIndex + 1] >= 1) ||
              this.spanArr[rowIndex] === 1)) ||
          (columnIndex >= 4 &&
            columnIndex <= 6 &&
            this.styleSpanArr[this.productSpanArr[rowIndex] - 1 + rowIndex] ===
              -1)
        )
      ) {
        return "thinBorder";
      } else {
        return "thickBorder";
      }
    }
  }

  protected getSummaryReport(report: Report | ZReport) {
    this.transactionSummaryReport = report;
  }

  protected getMoneyReport(report: ZReport | Report) {
    if (this.selectedReport[1].value === "money_actions") {
      this.report.data = (report as Report).rows.map((c: object) => {
        let obj = {};
        map(c, (v, k) => {
          const b = formatNumber(v);
          obj = { ...obj, [k]: b };
        });
        return obj;
      });

      this.report.headers = (report as Report).columns.map((c: string) => {
        return {
          text: this.$t(
            [
              "reports_group",
              this.selectedReport[0].value,
              this.selectedReport[1].value,
              !isNaN(+c.replace("field", "")) ? "field" : c
            ].join("."),
            {
              value: !isNaN(+c.replace("field", ""))
                ? c.replace("field", "")
                : ""
            }
          ),
          value: c
        };
      });
      return;
    }
    forEach(report, (value: any, key) => {
      this.zreports[key].headers = value.columns.map((c: string) => {
        return {
          text: this.$t(
            [
              "reports_group",
              this.selectedReport[0].value,
              this.selectedReport[1].value,
              !isNaN(+c.replace("field", "")) ? "field" : c
            ].join("."),
            {
              value: !isNaN(+c.replace("field", ""))
                ? c.replace("field", "")
                : ""
            }
          ),
          value: c
        };
      });
      this.zreports[key].data = value.rows.map((c: object) => {
        let obj = {};
        map(c, (v, k) => {
          const b = formatNumber(v);
          obj = { ...obj, [k]: b };
        });
        return obj;
      });
    });
  }

  protected async getReport() {
    this.loading = true;
    this.resetReports();
    this.printDisabled = !this.reportTypes.includes(
      this.selectedReport[1].value
    );
    if (this.selectedReport[0].value === "timeclock" && this.appliedFilters) {
      if (this.appliedFilters.sort_by) {
        this.selectedReport[1].value = `report_by_${
          this.appliedFilters.sort_by
        }`;
        delete this.appliedFilters.sort_by;
      }
    }
    this.fetchedReport = this.selectedReport[1].value;
    try {
      const dataReport:
        | BuiltReport
        | ZReport
        | Report = await reportsService.getReport(
        this.selectedReport[0].value,
        this.selectedReport[1].value,
        this.metaSearchFilters,
        this.report.pagination!
      );
      this.appliedFilters = {
        ...this.appliedFilters,
        transaction_control_number: dataReport.filter
          ? dataReport.filter!.transaction_control_number
          : null,
        report_generation_time: dataReport.filter
          ? dataReport.filter!.report_generation_time
          : null
      };
      this.report.pagination = (dataReport as BuiltReport).pagination || null;
      let setTypeCurrentReport: {
        [key: string]: {
          data: ZReport | Report;
          action: (report: ZReport | Report) => void;
        };
      } = {
        money: {
          action: this.getMoneyReport,
          data: dataReport as ZReport
        },
        transfer: {
          action:
            this.selectedReport[1].value === "transfers"
              ? this.getTransferReport
              : this.getVoidedTransferReport,
          data: dataReport as Report
        },
        timeclock: {
          action: this.getTimeclockReport,
          data: dataReport as Report
        }
      };

      if (this.selectedReport[1].value === "retail_summary_report") {
        setTypeCurrentReport = {
          ...setTypeCurrentReport,
          retail: {
            action: this.getSummaryReport,
            data: dataReport as Report | ZReport
          }
        };
      }

      if (
        this.appliedFilters.breakdown_by &&
        this.appliedFilters.breakdown_by !== "PRODUCT" &&
        this.selectedReport[1].value === "retail_sales_breakdown"
      ) {
        setTypeCurrentReport = {
          ...setTypeCurrentReport,
          retail: {
            action: this.getRetailSalesBreakDown,
            data: dataReport as Report
          }
        };
      }

      if (
        Object.keys(setTypeCurrentReport).includes(this.selectedReport[0].value)
      ) {
        const REPORT_SELECTED =
          setTypeCurrentReport[this.selectedReport[0].value];
        REPORT_SELECTED.action(REPORT_SELECTED.data);
      } else {
        this.getCommonReport(dataReport as BuiltReport);
      }
      this.loading = false;
      if (
        this.selectedReport[0].value === "prescription" &&
        this.selectedReport[1].value === "asap" &&
        this.hasPermission(policyList.printReports)
      ) {
        this.asapReportGenerated = true;
      }
    } catch (e) {
      this.loading = false;
      if (this.selectedReport[0].value !== "prescription") {
        this.fetchedReport = null;
      }
    }
  }

  protected currentData(page: number, from: string) {
    if (this.report.pagination) {
      this.report.pagination.currentPage = page;
      reportsService.setPaginationQuery(this.report.pagination);
    }
    if (from === "runBtn") {
      if (
        this.selectedReport[0].value === "prescription" &&
        this.selectedReport[1].value === "asap"
      ) {
        this.appliedFilters.report_generation_time = new Date().toISOString();
        this.appliedFilters.transaction_control_number = "";
      }
    }
    this.getReport();
  }

  protected onSizeChange(rows: number) {
    this.report.pagination!.itemsPerPage = rows;
    this.currentData(1, "pagination");
  }

  protected sortChange(data: any) {
    let totalRow = [];
    if (
      this.selectedReport[1] &&
      this.selectedReport[1].value === "retail_sales_breakdown"
    ) {
      this.loadingSort = true;
      const index = this.report.data.findIndex(
        item => item.product_name === "Totals"
      );
      totalRow = cloneDeep(this.report.data[index]);
      this.report.data.splice(index, 1);
    }
    if (
      [
        "tax_breakdown_collected",
        "overall_tax_collected",
        "average_price_per_unit",
        "average_profit_per_unit",
        "cost_of_goods_sold",
        "discount_totals",
        "post_discount_totals",
        "pre_discount_total",
        "profit",
        "original_inventory_input_cost",
        "new_inventory_cost",
        "amount",
        "running_total_in_safe",
        "running_till_total",
        "till_slippage"
      ].includes(data.prop)
    ) {
      this.formatReportNumericalData(data, "$");
    } else if (
      [
        "overall_tax_rate",
        "tax_breakdown_rate",
        "percentage_overall_sales_profit",
        "percentage_sales_by_quantity",
        "percentage_sales_by_total_sales",
        "profit_percentage",
        "waste_percentage",
        "yield_percentage"
      ].includes(data.prop)
    ) {
      this.formatReportNumericalData(data, "%");
    } else if (["usable_weight"].includes(data.prop)) {
      this.formatReportNumericalData(data, "g");
    } else {
      this.report.data = orderBy(
        this.report.data,
        data.prop,
        data.order === "ascending" ? "asc" : "desc"
      );
    }
    if (
      this.selectedReport[1] &&
      this.selectedReport[1].value === "retail_sales_breakdown"
    ) {
      this.report.data.push(totalRow);
      this.$nextTick(() => {
        this.loadingSort = false;
      });
    }
    if (this.report.pagination) {
      this.report.pagination.currentPage = 1;
    }
  }
  protected formatReportNumericalData(data: any, symbol: string) {
    const key = data.prop;
    const rowValue = this.report.data.map(rowItem =>
      rowItem[key] === null || rowItem[key] === "-"
        ? 0
        : +rowItem[key].replace(/[$%,g ]/g, "")
    );
    this.report.data.map((item, ind) => {
      item[key] = rowValue[ind];
    });
    this.report.data = orderBy(
      this.report.data,
      data.prop,
      data.order === "ascending" ? "asc" : "desc"
    );
    this.$nextTick(() => {
      this.report.data.map(item => {
        if (item.product_name !== "Totals") {
          switch (symbol) {
            case "$":
              return (item[key] = `$ ${item[key]}`);
            case "%":
              return (item[key] = `${item[key]} %`);
            case "g":
              return (item[key] = `${item[key]} g`);
            default:
              return "";
          }
        }
      });
    });
  }

  protected resetReports() {
    this.zreports = {
      retail_report: { headers: [], data: [] },
      safe_report: { headers: [], data: [] },
      till_report: { headers: [], data: [] }
    };
    this.transactionSummaryReport = null;
    this.transferReport = null;
    this.voidedTransferReport = null;
    this.timeClockReport = null;
    this.report = {
      headers: [],
      data: [],
      pagination: null
    };
    this.retailSalesBreakDown = null;
  }

  protected showReportModal(
    message: string,
    size: "normal" | "full_width" | "fit" | "min" | "small",
    action: string
  ) {
    this.$modals
      .load(
        ReportModalComponent,
        {
          size
        },
        {
          modalData: {
            body: message,
            showResolveBtn: true,
            showRejectBtn: false,
            resolveBtnText: this.$t("ok")
          }
        }
      )
      .then(() => {
        if (action === "export") {
          reportsService.getReportDat(
            this.selectedReport[0].value,
            this.selectedReport[1].value,
            this.metaSearchFilters
          );
        }
      });
  }

  protected mounted() {
    this.mocked = reportsCategories.reduce(
      (acc: ReportCategory[], category) => {
        if (
          (category.visibleConditon && category.visibleConditon()) ||
          !category.visibleConditon
        ) {
          const filteredChildren = category.children.filter(child => {
            // if child value equals monthly_report then currentLocation must be in Oklahoma
            if (
              child.value === "monthly_report" &&
              this.currentLocation.state!.code !== "OK"
            ) {
              return false;
            } else if (child.viewPolicy) {
              return this.hasPermission(child.viewPolicy);
            }
            return true;
          });
          if (filteredChildren.length) {
            category.children = filteredChildren;
            acc.push(category);
          }
        }
        return acc;
      },
      []
    );

    this.setPageNav({
      title: "reports_tool",
      rightActions: {
        generalActions: () => this.actions
      },
      secondaryActions: {
        component: MultiLevelSelectComponent,
        props: {
          items: this.mocked,
          placeholder: this.$t("select_report"),
          itemLabel: "name",
          itemKey: "value",
          running: () => this.loading
        },
        events: [
          { event: "input", callback: this.clearAll },
          { event: "showFilters", callback: this.showFilters },
          { event: "getReport", callback: () => this.currentData(1, "runBtn") }
        ]
      }
    });
  }
}
