import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./RowViewTable.vue";

@Component({
  mixins: [Template]
})
export default class CustomTable extends Vue {
  @Prop({ required: true })
  public headers!: any[];
  @Prop({ required: true })
  public data!: any[];

  protected async mounted() {
    this.$emit("tableMounted");
  }
}
