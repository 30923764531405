import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ColumnViewTable.vue";

@Component({
  mixins: [Template]
})
export default class ColumnViewTable extends Vue {
  @Prop({ default: {} }) public report!: {
    [key: string]: { headers: any[]; data: any[] };
  };
  @Prop({ required: true })
  public rowHeights!: any[];
  protected async created() {
    this.$emit("readyToPrint");
  }
}
