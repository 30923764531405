import { formatNumber } from "@/filters/currency.filter";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReportZOut.template.vue";
@Component({
  mixins: [Template]
})
export default class ReportZOut extends Vue {
  public titles = ["Retail Sale"];
  public formatNumber = formatNumber;
  @Prop({ default: {} }) public reports!: {
    [key: string]: { headers: any[]; data: any[] };
  };

  private isArray = isArray;
  private isPlainObject = isPlainObject;
  private isEmpty = isEmpty;

  public getTextHeader(
    dataKey: string,
    headers: Array<{ text: string; value: string }>
  ) {
    const header = headers.find(item => item.value === dataKey);
    return (header && header.text) || this.$t(dataKey);
  }

  public conditionalHeader(dataKey: string) {
    return !["user", "start_date", "end_date"].includes(dataKey);
  }

  protected getData(data: any) {
    let obj: { [key: string]: string | number } = {};
    forEach(data, (val, key) => {
      if (key === "user" || key === "start_date" || key === "end_date") {
        obj = {
          ...obj,
          [key]:
            key === "start_date" || key === "end_date"
              ? fnsFormatDate(
                  new Date(val),
                  FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
                )
              : val
        };
      }
      return obj;
    });
    return obj;
  }

  protected getOrderedData(data: any, key: string) {
    const obj: any = {};
    if (key === "retail_report") {
      Object.keys(data)
        .sort()
        .forEach(k => {
          obj[k] = data[k];
        });
      return obj;
    }
    return data;
  }
}
