import { formatNumber } from "@/filters/currency.filter";
import { Report, ZReport } from "@/interfaces/report";
import { number, string } from "mathjs";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "../ReportSalesSummary/ReportSalesSummary.template.vue";
@Component({
  mixins: [Template]
})
export default class ReportSalesSummary extends Vue {
  @Prop({ default: {} }) public reports!: Report | ZReport;
  public formatNumber = formatNumber;
  public totalSales!: string[];
  public avgTicketPrice: string[] | number = 0;

  public getSalesSummaryData(report: Report) {
    const header = report.columns;
    const data = report.rows;
    let reportsArray = [];
    const result: { [key: string]: string[] } = {};
    header.forEach((key: string, index: number) => (result[key] = data[index]));
    reportsArray = Object.entries(result);
    if (reportsArray.length > 3) {
      this.totalSales = reportsArray[3][1];
    }
    return reportsArray;
  }

  public getTransactionData(report: Report) {
    const header = report.columns;
    const data = report.rows;
    let reportsArray: Array<[string, string[] | number]> = [];
    const result: { [key: string]: string[] } = {};
    header.forEach((key: string, index: number) => (result[key] = data[index]));
    reportsArray = Object.entries(result);
    reportsArray.splice(5, 10);
    this.avgTicketPrice =
      reportsArray[0][1] > 0
        ? Number(this.totalSales) / Number(reportsArray[0][1])
        : 0;
    reportsArray.push(["Average Ticket Price", this.avgTicketPrice]);
    return reportsArray;
  }

  public getCannabisQtyData(report: Report) {
    const header = report.columns;
    const data = report.rows;
    let reportsArray = [];
    const result: { [key: string]: string[] } = {};
    header.forEach((key: string, index: number) => (result[key] = data[index]));
    reportsArray = Object.entries(result);
    reportsArray.splice(0, 5);
    reportsArray.splice(2);
    return reportsArray;
  }

  public getCannabisWtData(report: Report) {
    const header = report.columns;
    const data = report.rows;
    let reportsArray = [];
    const result: { [key: string]: string | string[] } = {};
    header.forEach((key: string, index: number) => (result[key] = data[index]));
    reportsArray = Object.entries(result);
    reportsArray.splice(0, 7);
    reportsArray.splice(2);
    // Addig grams to units dispensed as this array has only 2 objects
    reportsArray[0][1] += "g";
    return reportsArray;
  }

  public getNonCannabisData(report: Report) {
    const header = report.columns;
    const data = report.rows;
    let reportsArray = [];
    const result: { [key: string]: string[] } = {};
    header.forEach((key: string, index: number) => (result[key] = data[index]));
    reportsArray = Object.entries(result);
    reportsArray.splice(0, 9);
    return reportsArray;
  }

  public getData(report: Report) {
    const heading = report.columns;
    const values = report.rows;
    const obj: { [key: string]: string[] } = {};
    heading.forEach((element: string, index: number) => {
      obj[element] = values[index];
    });
    return obj;
  }
}
