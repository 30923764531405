import { FieldType, Form } from "@/interfaces/fields";
import { ZReport } from "@/interfaces/report";
import { i18n } from "@/plugins/i18n";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { integrationService } from "@/services/integration.service";
import { locationService } from "@/services/location.service";
import { memberLevelService } from "@/services/memberLevel.service";
import { productService } from "@/services/product.service";
import { productCategoryService } from "@/services/productCategory.service";
import { strainService } from "@/services/strain.service";
import { usersService } from "@/services/user.service";
import { vendorService } from "@/services/vendor.service";
import { store } from "@/store";
import { batchActionFilters } from "@/utils/batch-actions.utils";
import { UTCTimeToLocalDate } from "@/utils/date-fns.utils";
import format from "date-fns/format";
import {
  DynamicFormComponent,
  FormField,
  FormFieldTypes,
  HelixDatePickerTypes
} from "helix-vue-components";

export enum status {
  active = "ACTIVE",
  checked_in = "CHECKED_ID"
}
export const ReportsInventoryProductsFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.name_includes"),
    name: "name_contains",
    component: FormFieldTypes.textField,
    options: { row: 1, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.strain"),
    name: "strain_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        strainService.get({
          "q[name_contains]": reference.search
        }),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.brand"),
    name: "brand_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        brandService.get({
          "q[name_contains]": reference.search
        }),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.products_category"),
    name: "product_category_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        productCategoryService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_type"),
    name: "batches-product-batch_type_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        batchTypeService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.default_vendor"),
    name: "vendor_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: async (reference: any) => {
        const vendors = await vendorService.getVendors(true, {
          "q[name_contains]": reference.search
        });
        return vendors;
      },
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.requires_inventory"),
    name: "require_inventory_eq",
    id: "vendor_id_eq",
    component: FormFieldTypes.checkbox,
    options: { row: 4, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.cannabis_product"),
    name: "marijuana_eq",
    id: "marijuana_eq",
    component: FormFieldTypes.checkbox,
    options: { row: 4, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.weighable"),
    name: "requires_weighing_eq",
    id: "requires_weighing_eq",
    component: FormFieldTypes.checkbox,
    options: { row: 4, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.member_discount_eligible"),
    name: "priceSettings-member_discount_eligible_eq",
    id: "priceSettings-member_discount_eligible_eq",
    component: FormFieldTypes.checkbox,
    options: { row: 4, flex: 4 }
  }
];

export const RepotsInventoryForensicsFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.strain"),
    name: "batch-strain_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        strainService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product"),
    name: "batch-product_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.brand"),
    name: "batch-product-brand_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        brandService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.products_category"),
    name: "batch-product-product_category_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        productCategoryService.get({
          "q[name_contains]": reference.search
        }),
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_type"),
    name: "batch-product-batch_type_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        batchTypeService.get({
          "q[name_contains]": reference.search
        }),
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "batch-batch_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 3, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "batch-tracking_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 4, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.action"),
    name: "action_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => batchActionFilters(),
      row: 4,
      flex: 4
    }
  }
];

export const ReportsInventoryBatchAdjustmentFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.date,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "batch-location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.brand"),
    name: "batch-product-brand_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        brandService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.products_category"),
    name: "batch-product-product_category_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        productCategoryService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_type"),
    name: "batch-batch_type_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        batchTypeService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "batch-batch_id_eq",
    component: FormFieldTypes.textField,
    options: {
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "batch-tracking_id_eq",
    component: FormFieldTypes.textField,
    options: {
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product"),
    name: "batch-concrete_sku_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "batch-user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 3,
      flex: 4
    }
  }
];

export const ReportsInventoryBatchAuditFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "batch-created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "batch-created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "batch-location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.brand"),
    name: "batch-product-brand_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        brandService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product_category"),
    name: "batch-product-product_category_id-eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        productCategoryService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_type"),
    name: "batch-product-batch_type_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        batchTypeService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "batch-batch_id_eq",
    component: FormFieldTypes.textField,
    options: {
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "batch-tracking_id_eq",
    component: FormFieldTypes.textField,
    options: {
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product"),
    name: "batch-product_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 3,
      flex: 4
    }
  }
];

export const ReportsInventoryCurrentInventoryFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.strain"),
    name: "strain_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        strainService.get({
          "q[name_contains]": reference.search
        }),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product"),
    name: "product_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product_category"),
    name: "product-product_category_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        productCategoryService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_type"),
    name: "product-batch_type_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        batchTypeService.get({
          "q[name_contains]": reference.search
        }),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.weighable"),
    name: "product-requires_weighing_eq",
    component: FormFieldTypes.checkbox,
    options: {
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.medicated"),
    name: "product-marijuana_eq",
    options: {
      row: 3,
      flex: 4
    }
  }
];

export const ReportsInventoryConversionFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 2,
      flex: 6,
      flexAlign: "mt-4"
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 2,
      flex: 6,
      flexAlign: "mt-4"
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "batch_uid_eq",
    component: FormFieldTypes.textField,
    options: { row: 3, flex: 6 }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "secondary_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 3, flex: 6 }
  }
];

export const ReportsProductPricesInventoryFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product_name"),
    name: "entity_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.member_level"),
    name: "member_level_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        memberLevelService.get({
          "q[name_contains]": reference.search
        }),
      row: 1,
      flex: 4
    }
  }
];

export const ReportsRetailTaxBreakdownFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.location"),
    name: "taxCategory-location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "orderItemTax-created_at_gteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "orderItemTax-created_at_lteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  }
];

export const ReportsRetailMonthlyReportFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "from_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "to_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 6
    }
  }
];
export const ReportsRetailMonthlyReportMetadata: Form = {
  columns: 2,
  submitEvent: "filter",
  fields: [
    {
      title: "",
      fields: [
        {
          type: FieldType.date,
          label: i18n.t("reports_group.filters.start_date").toString(),
          modelField: "from_date",
          options: {
            pickerType: HelixDatePickerTypes.datetime,
            pickerOptions: {
              type: HelixDatePickerTypes.datetime,
              "value-format": "yyyy-MM-dd hh:mm:ss A",
              format: "MM/dd/yyyy hh:mm a",
              "default-value": new Date(new Date().setHours(0, 0, 0, 0))
            }
          }
        },
        {
          type: FieldType.date,
          label: i18n.t("reports_group.filters.end_date").toString(),
          modelField: "to_date",
          options: {
            pickerType: HelixDatePickerTypes.datetime,
            pickerOptions: {
              type: HelixDatePickerTypes.datetime,
              "value-format": "yyyy-MM-dd hh:mm:ss A",
              format: "MM/dd/yyyy hh:mm a",
              "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
              endDateDefaultValue: {
                hours: 23,
                min: 59,
                sec: 0,
                ms: 0
              }
            }
          }
        }
      ]
    }
  ]
};

export const ReportsRetailSalesFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "order-location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "order-user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.transaction_type"),
    name: "preOrder-transaction_type",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "CALL_IN", text: "Call-In" },
        { value: "IN_STORE", text: "In-Store" },
        { value: "ONLINE", text: "Online" },
        { value: "ORDER", text: "Order" }
      ],
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.source"),
    name: "preOrder-source",
    component: FormFieldTypes.autocomplete,
    options: {
      disableCondition: model =>
        !(model["preOrder-transaction_type"] === "ONLINE"),
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: { search: string }) => {
        return integrationService.searchApps(reference.search);
      },
      row: 2,
      flex: 4
    }
  }
];

export const ReportsRetailReportsExchangeFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.product"),
    name: "orderItem-product_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "sku",
      selectOptions: (reference: any) =>
        productService.findByName(reference.search),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "orderItem-batch-batch_uid_or_batch-biotrack_traceability_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 2, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "orderItem-batch-tracking_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 2, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "orderRefund-user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 3,
      flex: 4
    }
  }
];

export const ReportsRetailSalesSummaryFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("start_date"),
    name: "start_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a"
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("end_date"),
    name: "end_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("location"),
    name: "location",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  }
];

export const ReportsRetailSalesBreakdownFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_gteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_lteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id",
    component: FormFieldTypes.autocomplete,
    defaultValue: store.getters["AuthModule/currentLocation"].id,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.breakdownby"),
    name: "breakdown_by",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "PRODUCT", text: "Product" },
        { value: "PRODUCT_CATEGORY", text: "Product Category" },
        { value: "BATCH_TYPE", text: "Batch Type" },
        { value: "BRAND", text: "Brand" },
        { value: "STRAIN", text: "Strain" },
        { value: "VENDOR", text: "Vendor" }
      ],
      row: 2,
      flex: 4
    }
  }
];

export const ReportsCustomerCustomerListFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.member_level"),
    name: "member_level_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        memberLevelService.get({
          "q[name_contains]": reference.search
        }),
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.filters.status"),
    name: "is_in_store_status",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "ACTIVE", text: "Active" },
        { value: "CHECKED_IN", text: "Checked_in" }
      ],
      row: 1,
      flex: 6
    }
  }
];

export const ReportsMoneyZOutFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "start_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "end_date",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 1,
      flex: 4
    }
  }
];

export const ReportsMoneyMoneyActionsFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id_is_in",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      vuetifyProps: {
        multiple: true,
        smallChips: true,
        deletableChips: true,
        cacheItems: true
      },
      selectOptions: (reference: any) => {
        return usersService.searchUsers(reference.search);
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.till_assigment"),
    name: "till_assignment_is_in",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      vuetifyProps: {
        multiple: true,
        smallChips: true,
        deletableChips: true,
        cacheItems: true
      },
      selectOptions: (reference: any) => {
        return usersService.searchUsers(reference.search);
      },
      row: 2,
      flex: 4,
      flexAlign: "mt-4"
    }
  },
  {
    label: i18n.t("reports_group.filters.action"),
    name: "actions_is_in",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      vuetifyProps: {
        multiple: true,
        smallChips: true,
        deletableChips: true,
        cacheItems: true
      },
      selectOptions: () => [
        {
          value: "Add Money to Safe (Deposit to Safe)",
          text: i18n.t("filter_reports.add_money_safe_actions")
        },
        {
          value: "Remove Money from Safe (Withdrawal from Safe)",
          text: i18n.t("filter_reports.remove_money_safe_actions")
        },
        {
          value: "Remove Money from Safe (Cash pickup Withdrawal)",
          text: i18n.t("filter_reports.remove_money_safe_cash_actions")
        },
        {
          value: "Payout from Safe",
          text: i18n.t("filter_reports.payout_from_safe_actions")
        },
        {
          value: "Employee Retail",
          text: i18n.t("filter_reports.employee_retail")
        },
        {
          value: "Void Payout from Safe",
          text: i18n.t("filter_reports.payout_void_from_safe_actions_actions")
        },
        {
          value: "Open Till",
          text: i18n.t("filter_reports.open_till_actions")
        },
        {
          value: "Close Till",
          text: i18n.t("filter_reports.close_till_actions")
        },
        {
          value: "Review Close Till",
          text: i18n.t("filter_reports.review_close_till_actions")
        },
        {
          value: "Add Money to Till from Safe (Remove from Safe to Till)",
          text: i18n.t("filter_reports.add_money_till_actions")
        },
        {
          value: "Remove Money from Till to Safe (or Undo Open Till)",
          text: i18n.t("filter_reports.add_money_till_safe_actions")
        },
        {
          value: "Payout from Till",
          text: i18n.t("filter_reports.payout_from_retail_actions")
        },
        {
          value: "Void Payout from Till",
          text: i18n.t("filter_reports.void_payout_from_retail_actions")
        },
        {
          value: "Employee Close Till",
          text: i18n.t("filter_reports.employee_close_till_actions")
        },
        {
          value: "Employee Payout From Till",
          text: i18n.t("filter_reports.employee_payout_from_till_actions")
        },
        {
          value: "Retail Sale",
          text: i18n.t("filter_reports.retail_sale_actions")
        },
        {
          value: "Retail Sale Modification",
          text: i18n.t("filter_reports.retail_sale_modification_actions")
        },
        {
          value: "Retail Sale Refund",
          text: i18n.t("filter_reports.retail_sale_refund_actions")
        },
        {
          value: "Void Retail Sale Refund",
          text: i18n.t("filter_reports.void_retail_sale_refund_actions")
        },
        {
          value: "Retail Sale Void",
          text: i18n.t("filter_reports.retail_sale_void_actions")
        },
        {
          value: "Retail Sale - Cashback",
          text: i18n.t("filter_reports.retail_sale_cashback")
        }
      ],
      row: 2,
      flex: 7,
      flexAlign: "mt-4"
    }
  }
];

export const ReportsTransfersFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_gteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_lteq",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        format: "MM/dd/yyyy hh:mm a",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.direction"),
    name: "type_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "OUTBOUND", text: "OUTBOUND" },
        { value: "INBOUND", text: "INBOUND" }
      ],
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.status"),
    name: "status_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        { value: "DRAFT", text: "DRAFT" },
        { value: "PENDING", text: "PENDING" },
        { value: "COMPLETED", text: "COMPLETED" },
        { value: "CANCELED", text: "CANCELED" },
        { value: "CLOSED", text: "CLOSED" }
      ],
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 2,
      flex: 4
    }
  }
];

export const ReportsVoidedInventoryTransfersFiltersFieldsConfig: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm a",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: (reference: any) =>
        usersService.searchUsers(reference.search),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.transfer_id"),
    name: "transfer_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 2, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.transfer_type"),
    name: "transfer_type_eq",
    component: FormFieldTypes.select,
    options: {
      associationText: "text",
      associationValue: "value",
      selectOptions: () => [
        {
          value: "Voided Inbound Vendor Transfer from Purchase Order",
          text: i18n.t("reports_group.filters.voided_inbound_purchase_order")
        },
        {
          value: "Voided Inbound Existing Inventory Transfer from New Batches",
          text: i18n.t("reports_group.filters.voided_inbound_from_new_batches")
        },
        {
          value: "Voided Inbound Vendor Transfer from New Batches",
          text: i18n.t(
            "reports_group.filters.voided_inbound_transfer_from_new_batches"
          )
        },
        {
          value: "Voided Outbound Location Transfer from Inventory",
          text: i18n.t(
            "reports_group.filters.voided_outbount_location_inventory"
          )
        },
        {
          value: "Voided Outbound Vendor Transfer from Inventory",
          text: i18n.t("reports_group.filters.voided_outbount_vendor_inventory")
        },
        {
          value: "Voided Outbound Location Transfer from Quote",
          text: i18n.t(
            "reports_group.filters.voided_outbount_location_from_quote"
          )
        },
        {
          value: "Voided Outbound Vendor Transfer from Quote",
          text: i18n.t(
            "reports_group.filters.voided_outbount_vendor_from_quote"
          )
        }
      ],
      row: 2,
      flex: 8
    }
  },
  {
    label: i18n.t("reports_group.filters.source_type"),
    name: "source_destination_type_eq",
    component: FormFieldTypes.autocomplete,
    options: {
      associationText: "name",
      associationValue: "value",
      dependEmitField: "source_destination_id_eq",
      selectOptions: () => [
        {
          name: "Vendor",
          value: "vendor"
        },
        {
          name: "Location",
          value: "location"
        }
      ],
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.source_destination"),
    name: "source_destination_id_eq",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      disableCondition: model => !model.source_destination_type_eq,
      dependOnField: "source_destination_type_eq",
      associationText: "name",
      associationValue: "id",
      selectOptions: async (scope: DynamicFormComponent) => {
        scope.currentModel!.test = scope.currentModel!.source_option;
        if (scope.currentModel!.source_destination_type_eq === "vendor") {
          const vendors = await vendorService.getVendors(true);
          return vendors;
        } else {
          return locationService.get();
        }
      },
      row: 3,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.batch_id"),
    name: "batch_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 3, flex: 4 }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    defaultValue: store.getters["AuthModule/currentLocation"].id,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 4,
      flex: 4
    }
  }
];

export const GroupCloseTill: ZReport = {
  till_report: {
    columns: [],
    rows: [
      {
        employee_count: ["total_employee_count", "employee_difference"],
        review_close_count: [
          "total_review_close_count",
          "review_close_difference"
        ],
        other_payment_methods: [
          "credit_card",
          "debit_card",
          "cash",
          "store_credit",
          "check"
        ],
        total_collected: ["total_till_collected"]
      }
    ]
  }
};

export const GroupsZout: ZReport = {
  till_report: {
    columns: [],
    rows: [
      {
        employee_count: ["total_employee_count", "employee_difference"],
        review_close_count: [
          "total_review_close_count",
          "review_close_difference"
        ],
        other_payment_methods: [
          "credit_card",
          "debit_card",
          "cash",
          "store_credit",
          "check"
        ],
        total_collected: ["total_till_collected"]
      }
    ]
  },
  retail_report: {
    columns: [],
    rows: [
      {
        a_total_retail_sales: [
          "total_retail_sales_tax_collected",
          "total_retail_sales",
          "total_retail_sales_sub_total"
        ],
        b_retail_sales: [
          "retail_sales_tax_collected",
          "retail_sales_total",
          "retail_sales_sub_total"
        ],
        c_refund_and_exchange: [
          "refund_exchange_sub_total",
          "refund_exchange_tax_collected",
          "refund_exchange_total"
        ]
      }
    ]
  }
};

export const MetrcRetailSalesFilterForm: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    defaultValue: format(new Date(), "yyyy-MM-dd"),
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.date,
        format: "MM/dd/yyyy",
        "value-format": "yyyy-MM-dd"
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    defaultValue: format(new Date(), "yyyy-MM-dd"),
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.date,
        format: "MM/dd/yyyy",
        "value-format": "yyyy-MM-dd"
      },
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    defaultValue: store.getters["AuthModule/currentLocation"].id,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 4
    }
  },
  {
    label: i18n.t("metrc_reports.filters.diagnostic_mode"),
    name: "diagnostic_mode_eq",
    component: FormFieldTypes.select,
    defaultValue: "No",
    options: {
      selectOptions: () => [
        { label: i18n.t("yes").toString(), val: "Yes" },
        { label: i18n.t("no").toString(), val: "No" }
      ],
      associationText: "label",
      associationValue: "val"
    }
  },
  {
    label: i18n.t("metrc_reports.filters.show_refunds"),
    name: "show_refunds_eq",
    component: FormFieldTypes.select,
    defaultValue: "Yes",
    options: {
      selectOptions: () => [
        { label: i18n.t("yes").toString(), val: "Yes" },
        { label: i18n.t("no").toString(), val: "No" }
      ],
      associationText: "label",
      associationValue: "val"
    }
  },
  {
    label: i18n.t("reports_group.filters.secondary_id"),
    name: "search_secondary_id_eq",
    component: FormFieldTypes.textField,
    options: { row: 2, flex: 4 }
  },
  {
    label: i18n.t("metrc_reports.filters.secondary_id_display"),
    name: "secondary_id_display_eq",
    component: FormFieldTypes.select,
    defaultValue: "Current Secondary ID",
    options: {
      selectOptions: () => ["Current Secondary ID", "Secondary ID At Sale"],
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("metrc_reports.filters.usable_weight_display"),
    name: "usable_weight_display_eq",
    component: FormFieldTypes.select,
    defaultValue: "Usable weight at sale",
    options: {
      selectOptions: () => ["Current Usable weight", "Usable weight at sale"],
      row: 2,
      flex: 4
    }
  }
];

export const TimeclockReportFilterForm: FormField[] = [
  {
    label: i18n.t("reports_group.filters.start_date"),
    name: "created_at_greater_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm:ss A",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        placeholder: "MM/DD/YYYY hh:mm:ss AM/PM",
        "default-value": new Date(new Date().setHours(0, 0, 0, 0))
      },
      row: 1,
      flex: 5
    }
  },
  {
    label: i18n.t("reports_group.filters.end_date"),
    name: "created_at_less_than_or_equal",
    component: FormFieldTypes.datepicker,
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.datetime,
        format: "MM/dd/yyyy hh:mm:ss A",
        "value-format": "yyyy-MM-dd hh:mm:ss A",
        placeholder: "MM/DD/YYYY hh:mm:ss AM/PM",
        "default-value": new Date(new Date().setHours(23, 59, 0, 0)),
        endDateDefaultValue: {
          hours: 23,
          min: 59,
          sec: 0,
          ms: 0
        }
      },
      row: 1,
      flex: 5
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id_eq",
    component: FormFieldTypes.autocomplete,
    defaultValue: store.getters["AuthModule/currentLocation"].id,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 2,
      flex: 4
    }
  },
  {
    label: i18n.t("reports_group.filters.user"),
    name: "user_id_is_in",
    component: FormFieldTypes.asyncAutocomplete,
    options: {
      associationText: "name",
      associationValue: "id",
      vuetifyProps: {
        multiple: true,
        smallChips: true,
        deletableChips: true,
        cacheItems: true
      },
      selectOptions: (reference: any) => {
        return usersService.searchUsers(reference.search);
      },
      row: 2,
      flex: 6
    }
  },
  {
    label: i18n.t("filter_reports.sort_by"),
    name: "sort_by",
    component: FormFieldTypes.select,
    options: {
      associationText: "name",
      associationValue: "value",
      selectOptions: () => [
        {
          name: "Employee",
          value: "employee"
        },
        {
          name: "Time",
          value: "time"
        }
      ],
      row: 3,
      flex: 4
    },
    defaultValue: "employee"
  }
];

export const PrescriptionAsapFilterForm: FormField[] = [
  {
    label: i18n.t("reports_group.filters.report_date"),
    name: "report_date",
    component: FormFieldTypes.datepicker,
    defaultValue: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    options: {
      pickerOptions: {
        type: HelixDatePickerTypes.date,
        "value-format": "yyyy-MM-dd HH:mm:ss",
        format: "MM/dd/yyyy"
      },
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.filters.location"),
    name: "location_id",
    component: FormFieldTypes.autocomplete,
    defaultValue: store.getters["AuthModule/currentLocation"].id,
    options: {
      associationText: "name",
      associationValue: "id",
      selectOptions: () => locationService.get(),
      row: 1,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.prescription.asap.reportType"),
    name: "prescription_report_type",
    component: FormFieldTypes.autocomplete,
    defaultValue: ["new", "revise", "void"],
    options: {
      associationText: "label",
      associationValue: "val",
      vuetifyProps: {
        multiple: true,
        smallChips: true,
        deletableChips: true,
        cacheItems: true
      },
      selectOptions: () => [
        {
          label: i18n.t("reports_group.prescription.asap.new").toString(),
          val: "new"
        },
        {
          label: i18n.t("reports_group.prescription.asap.revise").toString(),
          val: "revise"
        },
        {
          label: i18n.t("reports_group.prescription.asap.void").toString(),
          val: "void"
        }
      ],
      row: 2,
      flex: 6
    }
  },
  {
    label: i18n.t("reports_group.prescription.asap.test"),
    name: "file_type",
    component: FormFieldTypes.select,
    defaultValue: "P",
    options: {
      selectOptions: () => [
        { label: i18n.t("yes").toString(), val: "T" },
        { label: i18n.t("no").toString(), val: "P" }
      ],
      associationText: "label",
      associationValue: "val",
      row: 2,
      flex: 6
    }
  }
];
