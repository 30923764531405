import { ReportModal } from "@/interfaces/notification";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReportModal.template.vue";

@Component({
  mixins: [Template]
})
export default class ReportModalComponent extends Vue {
  @Prop({ required: true })
  public modalData!: ReportModal;

  public accept() {
    this.$emit("resolve");
  }

  public cancel() {
    this.$emit("reject");
  }
}
