import { Form } from "@/interfaces/fields";
import { DynamicFormComponent, FormResponse } from "helix-vue-components";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./ReportFilters.template.vue";
@Component({
  mixins: [Template],
  components: {
    DynamicFormComponent
  }
})
export class ReportsFiltersModalComponent extends Vue {
  @Prop() public form!: Form;
  @Prop() public selectedReport!: Array<{ [key: string]: any; value: string }>;
  // 30 days timeframe is required between start & end dates
  public timeframe: number = 30;
  public renderWarning: boolean = false;
  // userResponse variable is created to display user the auto corrected dates
  public userResponse: boolean = true;
  public areValidDates: boolean = true;
  protected applied: any = null;

  protected async apply() {
    const resp: FormResponse = await (this.$refs[
      "report-form"
    ] as DynamicFormComponent).submit();
    if (
      (this.selectedReport[0].value === "inventory" &&
        this.selectedReport[1].value === "forensics") ||
      (this.selectedReport[0].value === "money" &&
        this.selectedReport[1].value === "money_actions")
    ) {
      // Case: If no dates are given default current date gets selected
      if (
        !resp.currentModel!.created_at_less_than_or_equal &&
        !resp.currentModel!.created_at_greater_than_or_equal &&
        !resp.currentModel!["batch-batch_id_eq"] &&
        !resp.currentModel!["batch-tracking_id_eq"]
      ) {
        // If user don't select either start or end date, it will select current day's date
        resp.currentModel!.created_at_greater_than_or_equal = new Date();
      }
      // Case: If only start date is given
      else if (
        resp.currentModel!.created_at_greater_than_or_equal &&
        !resp.currentModel!.created_at_less_than_or_equal &&
        !resp.currentModel!["batch-batch_id_eq"] &&
        !resp.currentModel!["batch-tracking_id_eq"]
      ) {
        if (this.userResponse) {
          this.userResponse = !this.userResponse;
          this.renderWarning = true;
          const startDate = resp
            .currentModel!.created_at_greater_than_or_equal.split("T")[0]
            .split("-");
          resp.currentModel!.created_at_less_than_or_equal = new Date(
            startDate[0] + "/" + startDate[1] + "/" + startDate[2]
          );
          // Add 30 days
          resp.currentModel!.created_at_less_than_or_equal.setDate(
            resp.currentModel!.created_at_less_than_or_equal.getDate() + 30
          );
          resp.currentModel!.created_at_less_than_or_equal.toISOString();
          this.renderWarning = false;
          this.areValidDates = true;
        } else {
          this.userResponse = !this.userResponse;
        }
      }
      // Case: If only end date is given
      else if (
        !resp.currentModel!.created_at_greater_than_or_equal &&
        resp.currentModel!.created_at_less_than_or_equal &&
        !resp.currentModel!["batch-batch_id_eq"] &&
        !resp.currentModel!["batch-tracking_id_eq"]
      ) {
        if (this.userResponse) {
          this.userResponse = !this.userResponse;
          this.renderWarning = true;
          const endDate = resp
            .currentModel!.created_at_less_than_or_equal.split("T")[0]
            .split("-");
          resp.currentModel!.created_at_greater_than_or_equal = new Date(
            endDate[0] + "/" + endDate[1] + "/" + endDate[2]
          );
          // Subract 30 days
          resp.currentModel!.created_at_greater_than_or_equal.setDate(
            resp.currentModel!.created_at_greater_than_or_equal.getDate() - 30
          );
          resp.currentModel!.created_at_greater_than_or_equal.toISOString();
          this.renderWarning = false;
          this.areValidDates = true;
        } else {
          this.userResponse = !this.userResponse;
        }
      }
      // Case: If both start & end dates are given
      else if (
        resp.currentModel!.created_at_greater_than_or_equal &&
        resp.currentModel!.created_at_less_than_or_equal &&
        !resp.currentModel!["batch-batch_id_eq"] &&
        !resp.currentModel!["batch-tracking_id_eq"]
      ) {
        if (this.userResponse) {
          let startDate = resp
            .currentModel!.created_at_greater_than_or_equal.split("T")[0]
            .split("-");
          startDate = new Date(
            startDate[0] + "/" + startDate[1] + "/" + startDate[2]
          );
          let endDate = resp
            .currentModel!.created_at_less_than_or_equal.split("T")[0]
            .split("-");
          endDate = new Date(endDate[0] + "/" + endDate[1] + "/" + endDate[2]);
          const diffDays = Math.ceil(
            Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
          );
          this.renderWarning = diffDays > 31 ? true : false;
          this.areValidDates = diffDays > 31 ? false : true;
        } else {
          this.userResponse = !this.userResponse;
        }
      }
    }
    if (resp.valid) {
      this.applied = resp.currentModel;
      if (this.areValidDates && this.userResponse) {
        this.$emit("resolve", this.applied);
      }
    }
  }

  protected close() {
    this.$emit("reject");
  }
}
