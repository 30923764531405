import {
  MetrcRetailSalesFilterForm,
  PrescriptionAsapFilterForm,
  ReportsCustomerCustomerListFiltersFieldsConfig,
  ReportsInventoryBatchAdjustmentFiltersFieldsConfig,
  ReportsInventoryBatchAuditFiltersFieldsConfig,
  ReportsInventoryConversionFiltersFieldsConfig,
  ReportsInventoryCurrentInventoryFiltersFieldsConfig,
  ReportsInventoryProductsFiltersFieldsConfig,
  ReportsMoneyMoneyActionsFiltersFieldsConfig,
  ReportsMoneyZOutFiltersFieldsConfig,
  ReportsProductPricesInventoryFiltersFieldsConfig,
  ReportsRetailMonthlyReportFieldsConfig,
  ReportsRetailReportsExchangeFiltersFieldsConfig,
  ReportsRetailSalesBreakdownFiltersFieldsConfig,
  ReportsRetailSalesFiltersFieldsConfig,
  ReportsRetailSalesSummaryFiltersFieldsConfig,
  ReportsRetailTaxBreakdownFiltersFieldsConfig,
  ReportsTransfersFiltersFieldsConfig,
  ReportsVoidedInventoryTransfersFiltersFieldsConfig,
  RepotsInventoryForensicsFiltersFieldsConfig,
  TimeclockReportFilterForm
} from "@/metadata/reports";
import { FormField } from "helix-vue-components";
export const registeredFormFilters: { [key: string]: FormField[] } = {
  "money.close_till": ReportsMoneyZOutFiltersFieldsConfig,
  "money.z_report": ReportsMoneyZOutFiltersFieldsConfig,
  "money.money_actions": ReportsMoneyMoneyActionsFiltersFieldsConfig,
  "inventory.products": ReportsInventoryProductsFiltersFieldsConfig,
  "inventory.forensics": RepotsInventoryForensicsFiltersFieldsConfig,
  "inventory.batch_adjustments": ReportsInventoryBatchAdjustmentFiltersFieldsConfig,
  "inventory.batch_audits": ReportsInventoryBatchAuditFiltersFieldsConfig,
  "inventory.inventory_conversion": ReportsInventoryConversionFiltersFieldsConfig,
  "retail.sales": ReportsRetailSalesFiltersFieldsConfig,
  "inventory.current_inventory": ReportsInventoryCurrentInventoryFiltersFieldsConfig,
  "retail.tax_breakdown": ReportsRetailTaxBreakdownFiltersFieldsConfig,
  "inventory.product_prices": ReportsProductPricesInventoryFiltersFieldsConfig,
  "retail.monthly_report": ReportsRetailMonthlyReportFieldsConfig,
  "transfer.transfers": ReportsTransfersFiltersFieldsConfig,
  "transfer.voided_inventory_transfers": ReportsVoidedInventoryTransfersFiltersFieldsConfig,
  "retail.refund_exchange": ReportsRetailReportsExchangeFiltersFieldsConfig,
  // Sales summary report modal component
  "retail.retail_summary_report": ReportsRetailSalesSummaryFiltersFieldsConfig,
  "customer.customer_list": ReportsCustomerCustomerListFiltersFieldsConfig,
  "metrc.retail_sales": MetrcRetailSalesFilterForm,
  "prescription.asap": PrescriptionAsapFilterForm,
  "timeclock.report_by_employee": TimeclockReportFilterForm,
  "timeclock.report_by_time": TimeclockReportFilterForm,
  "retail.retail_sales_breakdown": ReportsRetailSalesBreakdownFiltersFieldsConfig
};
