import { policyList } from "@/enums/permissions";
import { store } from "@/store";
import { BooleanCheck } from "@/types/types";
export interface ReportCategory {
  name: string;
  value: string;
  children: ReportCategoryChildren[];
  visibleConditon?: BooleanCheck;
}

export interface ReportCategoryChildren {
  name: string;
  value: string;
  viewPolicy: string | null;
}

export const reportsCategories: ReportCategory[] = [
  {
    name: "Inventory",
    value: "inventory",
    children: [
      {
        name: "Forensics Report",
        value: "forensics",
        viewPolicy: policyList.viewForensicsReport
      },
      {
        name: "Products Report",
        value: "products",
        viewPolicy: policyList.viewProductsReport
      },
      {
        name: "Batch Adjustments",
        value: "batch_adjustments",
        viewPolicy: policyList.viewBatchAdjustmentsReport
      },
      {
        name: "Batch Audit",
        value: "batch_audits",
        viewPolicy: policyList.viewBatchAuditReport
      },
      {
        name: "Current Inventory",
        value: "current_inventory",
        viewPolicy: policyList.viewCurrentInventoryReport
      },
      {
        name: "Product Prices",
        value: "product_prices",
        viewPolicy: policyList.viewProductPricesReport
      },
      {
        name: "Conversion",
        value: "inventory_conversion",
        viewPolicy: policyList.viewConversionsReport
      }
    ]
    // This categories will be displayed on report release.
    // No provider endpoint implemented
    // },
    // {
    //   name: "Batch transfer",
    //   value: "batch-transfer"
    // },
    // {
    //   name: "Retail transfer",
    //   value: "retail-transfer"
    // },
    // {
    //   name: "Money Management",
    //   value: "money-management"
    // },
    // {
    //   name: "Employee",
    //   value: "employee"
  },
  {
    name: "Retail",
    value: "retail",
    children: [
      {
        name: "Tax Breakdown",
        value: "tax_breakdown",
        viewPolicy: policyList.viewTaxBreakdownReport
      },
      {
        name: "OK Monthly Sales",
        value: "monthly_report",
        viewPolicy: policyList.viewOkMonthlySalesReport
      },
      {
        name: "Sales",
        value: "sales",
        viewPolicy: policyList.viewSalesReport
      },
      {
        name: "Refund",
        value: "refund_exchange",
        viewPolicy: policyList.viewRefundReport
      },
      {
        name: "Retail Sales Summary",
        value: "retail_summary_report",
        viewPolicy: policyList.viewRetailSalesSummaryReport
      },
      {
        name: "Retail Sales Breakdown",
        value: "retail_sales_breakdown",
        viewPolicy: policyList.viewRetailSalesBreakdownReport
      }
    ]
  },
  {
    name: "Customer",
    value: "customer",
    children: [
      {
        name: "Customer List",
        value: "customer_list",
        viewPolicy: policyList.viewCustomerListReport
      }
    ]
  },
  {
    name: "Money",
    value: "money",
    children: [
      {
        name: "Close Tills",
        value: "close_till",
        viewPolicy: policyList.viewCloseTillReport
      },
      {
        name: "Z Out",
        value: "z_report",
        viewPolicy: policyList.viewZOutReport
      },
      {
        name: "Money Actions",
        value: "money_actions",
        viewPolicy: policyList.viewMoneyActionsReport
      }
    ]
  },
  {
    name: "Transfers",
    value: "transfer",
    children: [
      {
        name: "Inventory Transfers",
        value: "transfers",
        viewPolicy: policyList.viewInventoryTransfersReport
      },
      {
        name: "Voided Inventory Transfers",
        value: "voided_inventory_transfers",
        viewPolicy: policyList.voidedInventoryTransfers
      }
    ]
  },
  {
    name: "Metrc",
    value: "metrc",
    visibleConditon: () => store.getters["TraceabilityModule/metrcConfig"],
    children: [
      {
        name: "Metrc Sales Report",
        value: "retail_sales",
        viewPolicy: policyList.metrcSalesReport
      }
    ]
  },
  {
    name: "Employee Reports",
    value: "timeclock",
    children: [
      {
        name: "Time Clock Events Report",
        value: "report_by_employee",
        viewPolicy: policyList.viewTimeClockEventsReport
      }
    ]
  },
  {
    name: "Prescription",
    value: "prescription",
    children: [
      {
        name: "ASAP 4.2 A",
        value: "asap",
        viewPolicy: policyList.viewAsapReport
      }
    ]
  }
];
