var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tranfer-voided-report-container"},[_c('div',[_c('v-layout',{attrs:{"column":""}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-layout',{staticClass:"container-loading",attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.data.length)?_c('div',_vm._l((_vm.data),function(report,j){return _c('div',{key:j,staticClass:"scroll-x"},[_c('div',{staticClass:"px-3 mt-5 font-weight-bold name"},[_vm._v(_vm._s(report.name))]),_vm._v(" "),_c('v-layout',{attrs:{"text-md-center":""}},[_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.voided_date"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.voided_by_user"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("reports_group.transfer.voided_inventory_transfers.location"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.date_of_transfer"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.transfer_id"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.original_user"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.transfer_type"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.source_destination"
              ))+"\n          ")])]),_vm._v(" "),_c('v-layout',{attrs:{"text-md-center":""}},[_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.voided.voided_date))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.voided.voided_by_user))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.voided.location))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v("\n            "+_vm._s(report.voided.date_of_transfer)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.voided.transfer_id))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.voided.original_user))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v("\n            "+_vm._s(report.voided.transfer_type)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v("\n            "+_vm._s(report.voided.source_destination)+"\n          ")])]),_vm._v(" "),_c('v-layout',_vm._l((report.columns),function(header,i){return _c('div',{key:i,staticClass:"font-weight-bold text-md-center table-column"},[_vm._v("\n            "+_vm._s(_vm.$t(
                ("reports_group.transfer.voided_inventory_transfers." + header)
              ))+"\n          ")])}),0),_vm._v(" "),_vm._l((report.rows),function(rows,r){return _c('v-layout',{key:r},_vm._l((rows),function(column,n){return _c('div',{key:n,staticClass:"table-column text-md-center"},[_vm._v("\n            "+_vm._s(column)+"\n          ")])}),0)}),_vm._v(" "),_c('v-layout',{attrs:{"text-md-center":""}},[_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t("reports_group.transfer.voided_inventory_transfers.subtotal"))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.total_discounts"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.total_fees"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.whole_order_total"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.payment_methods"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.total_payments"
              ))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"table-column font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.$t(
                "reports_group.transfer.voided_inventory_transfers.total_outstanding"
              ))+"\n          ")])]),_vm._v(" "),_c('v-layout',{attrs:{"text-md-center":""}},[_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.totals.subtotal))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.totals.total_discounts))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.totals.total_fees))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v("\n            "+_vm._s(report.totals.whole_order_total)+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"methods-items"},[_vm._v(_vm._s(report.totals.payment_methods))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v(_vm._s(report.totals.total_payments))]),_vm._v(" "),_c('div',{staticClass:"table-column"},[_vm._v("\n            "+_vm._s(report.totals.total_outstanding)+"\n          ")])])],2)}),0):_c('div',{staticClass:"empty-block"},[_c('span',{staticClass:"empty-text"},[_vm._v("\n        "+_vm._s(_vm.$t('no_data'))+"\n        ")])])]),_vm._v(" "),_c('v-layout',{attrs:{"justify-center":""}},[_c('table-pagination-component',{staticClass:"ma-4",attrs:{"items":_vm.data,"pagination":_vm.pagination,"color":"primary"},on:{"changePagination":_vm.changePagination}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }